import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, TextField, Alert, Snackbar, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LockResetIcon from '@mui/icons-material/LockReset';
import { generateRandomPassword } from '../utils/passwordUtil';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { LoadingButton } from '@mui/lab';

const GENERATE_PASSWORD_LENGTH = 8;

function ChangeCredentialsDialog(props) {
    const { user, onDialogClose, afterChangeCredentials, open } = props;
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/user/change-credentials`, method: "POST" });
    const [password, setPassword] = useState("");
    const [userName, setUserName] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);

    const changePassword = (password) => {
        setPassword(password);
    };

    const changeUserName = (userName) => {
        setUserName(userName);
    };

    const resetPassword = () => {
        if (password === "" && userName === user.userName) {
            closeDialog();
            return;
        }
        runFetch({
            body: {
                userId: user.id,
                userName: userName === user.userName ? undefined : userName,
                password: password === "" ? undefined : password
            }
        });
    };

    useEffect(() => {
        if (user) {
            setUserName(user.userName);
        }
    }, [user]);

    useEffect(() => {
        if (!loading && !error && data) {
            closeDialog();
            afterChangeCredentials();
        } else if (!loading && error) {
            setErrorAlertOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    const generatePassword = () => {
        const generatedPassword = generateRandomPassword(GENERATE_PASSWORD_LENGTH);
        setPassword(generatedPassword);
    };

    const closeDialog = () => {
        setPassword("");
        onDialogClose();
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Dialog
                maxWidth="sm"
                onClose={() => closeDialog()}
                aria-labelledby="new-user-dialog-title"
                open={open}
                transitionDuration={400}
            >
                <DialogTitle id="new-user-dialog-title">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5" component="h5">
                                Bejelentkezési adatok megváltoztatása
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton aria-label="close" onClick={() => closeDialog()} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2} sx={{ padding: '8px' }}>
                        <Grid item>
                            <TextField label="Felhasználónév" variant="outlined" value={userName} onChange={(event) => changeUserName(event.target.value)} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item container direction="row" alignItems="center">
                            <Grid item xs={10}>
                                <TextField label="Jelszó" variant="outlined" value={password} onChange={(event) => changePassword(event.target.value)} sx={{ width: '100%' }} />
                            </Grid>
                            <Grid item xs={2} container justifyContent="center">
                                <Tooltip title="Jelszó generálása">
                                    <IconButton aria-label="generate-password" onClick={() => generatePassword()} size="large" variant="filled">
                                        <LockResetIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item>
                                <Alert severity="warning">A bejelentkezési adatokat küldd el a felhasználónak, legközelebb ezekkel fog tudni bejelentkezni. A jelszó módosítása esetén első bejelentkezéskor a felhasználónak ezt a jelszót meg kell majd változtatnia.</Alert>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton sx={{ color: "#555" }} loading={loading} onClick={() => resetPassword()}>Mentés</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={() => setErrorAlertOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setErrorAlertOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {getReadableErrorMessage(error)}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ChangeCredentialsDialog;