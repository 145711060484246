export const getReadableErrorMessage = (error) => {
    switch (error) {
        case "WRONG_CREDENTIALS":
            return "Hibás felhasználónév vagy jelszó!";
        case "CLASS_NOT_EXISTS":
            return "Nem létező óra.";
        case "CLASS_IS_FULL":
            return "Már nincs szabad hely az órán, így a jelentkezés sikertelen.";
        case "CLASS_IS_IN_THE_PAST":
            return "Erre az órára már túl késő jelentkezni!";
        case "PASS_NOT_EXISTS":
            return "A kért bérlet nem létezik!";
        case "RESERVATION_NOT_EXISTS":
            return "A foglalás nem létezik!";
        case "TOO_LATE_TO_CHECK_OUT_MORNING":
            return "Sikertelen lejelentkezés: délelőtti óráról előző nap 22:00 óra előtt van lehetőség lejelentkezni.";
        case "TOO_LATE_TO_CHECK_OUT":
            return "Sikertelen lejelentkezés: az óra kezdetéig kevesebb, mint 8 óra van hátra.";
        case "ALREADY_CHECKED_IN":
            return "Már be vagy jelentkezve erre az órára.";
        case "USER_NOT_EXISTS":
            return "A felhasználó nem létezik.";
        case "DOES_NOT_HAVE_PASS":
            return "Nincs érvényes bérleted!";
        case "NO_SPACE_ON_PASS":
            return "Nincs már szabad hely a bérleteden!";
        case "USERNAME_EXISTS":
            return "Ez a felhasználónév már foglalt!";
        case "CLASS_OVERLAPS":
            return "Az óra időpontja ütközik egy másik órával!";
        case "ADMIN_CANNOT_CHECKIN":
            return "Adminként nem jelentkezhetsz be órára!";
        case "ADMIN_CANNOT_CHECKOUT":
            return "Adminként nem jelentkezhetsz ki óráról!";
        case "ALREADY_HAS_A_PASS":
            return "A felhasználónak már van érvényes bérlete! Érvénytelenítés esetén előbb állítsd a meglévő bérlet lejárati idejét egy múltbeli dátumra!";
        case "HAS_ACTIVE_RESERVATIONS":
            return "A felhasználó be van jelentkezve jövőbeli órára! Új bérletet csak akkor lehet létrehozni, ha a felhaszálónak már nincs jövőbeli bejelentkezése!";
        case "SOMETHING_WENT_WRONG":
            return "Valami hiba történt.";
        default:
            return "Valami hiba történt.";
    }
};