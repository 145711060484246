export const getDatePart = (dateString) => {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    return date.toLocaleDateString('hu-HU').replaceAll(' ', '');
};

export const getReadableDateTime = (dateTimeString) => {
    if (!dateTimeString) {
        return "";
    }
    const date = new Date(dateTimeString);
    return `${date.toLocaleDateString('hu-HU').replaceAll(' ', '')} ${date.toLocaleTimeString('hu-HU')}`;
};

export const getDateForRequest = (dateString) => {
    if (!dateString) {
        return "";
    }
    const date = new Date(dateString);
    const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${date.getFullYear()}-${month}-${day}`;
};

export const getDayNameByIndex = (dayIndex) => {
    switch (dayIndex) {
        case 0: return "Hétfő";
        case 1: return "Kedd";
        case 2: return "Szerda";
        case 3: return "Csütörtök";
        case 4: return "Péntek";
        default: return "Ismeretlen nap";
    }
};