import './Header.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Link, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

function Header() {
  const navigate = useNavigate();

  const navigateToLoginPage = () => {
    navigate('/login');
  }

  return (
    <Grid>
      <Grid container direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap" className="header">
        <Grid item>
          <img src="images/logo.svg" alt="Air Fitness logo" className="logo" />
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
            <Grid item className="header-content">
              <Grid container direction="row" alignItems="center">
                <LocationOnIcon className="icon" />
                <Link href="https://www.google.hu/maps/place/Vecs%C3%A9s,+J%C3%B3kai+M%C3%B3r+u.+85,+2220/@47.4048496,19.2778304,17z/data=!3m1!4b1!4m5!3m4!1s0x4741c0224767986b:0x614e4c02ae73b27!8m2!3d47.4048496!4d19.2800244"
                  target="_blank" className="link" underline="none" color="initial">
                  <Typography>2220 Vecsés, Jókai Mór utca 85.</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid item className="header-content">
              <Grid container direction="row" alignItems="center">
                <PhoneIcon className="icon" />
                <Typography color="initial">+36 70 605 6010</Typography>
              </Grid>
            </Grid>
            <Grid item className="header-content">
              <Grid container direction="row" alignItems="center">
                <EmailIcon className="icon" />
                <Link href="mailto:fruzsina.polesport@gmail.com" className="link" underline="none" color="initial">
                  <Typography color="initial">fruzsina.polesport@gmail.com</Typography>
                </Link>
              </Grid>
            </Grid>
            <Grid item className="separator-line header-content" />
            <Grid item>
              <Link
                href="https://www.facebook.com/airfitnessvecses"
                target="_blank"
                className="link"
                underline="hover">
                <FacebookIcon className="icon" sx={{ color: "#555" }} />
              </Link>
            </Grid>
            <Grid item>
              <Grid container direction="row" alignItems="center">
                <Link
                  href="https://www.instagram.com/airfitnessvecses/"
                  target="_blank"
                  className="link"
                  underline="hover">
                  <InstagramIcon className="icon" sx={{ color: "#555" }} />
                </Link>
              </Grid>
            </Grid>
            <Grid item>
              <Link to={'/login'}>
                <Button variant="outlined" className="login-button" onClick={() => navigateToLoginPage()}>Bejelentkezés</Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
