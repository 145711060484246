import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, Alert, Snackbar, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { getPassName } from '../utils/translationUtils';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';

function NewPassDialog(props) {
    const { user, onDialogClose, afterNewPassAdded, open } = props;
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/pass/new`, method: "POST" });
    const { runFetch: typesRunFetch, data: typesData, error: typesError, loading: typesLoading } = useFetch({ url: `${BASE_URL}/pass/types`, method: "GET" });
    const [passTypes, setPassTypes] = useState([]);
    const [selectedPassType, setSelectedPassType] = useState("");
    const [expirationDate, setExpirationDate] = useState("");
    const [createWasJustClicked, setCreateWasJustClicked] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);

    const getDefaultDate = () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 30);
        return currentDate;
    };

    useEffect(() => {
        typesRunFetch({});
        setExpirationDate(getDefaultDate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!typesLoading && !typesError && typesData) {
            const formattedPassTypes = typesData.map(type => ({ id: type.id, name: getPassName(type.passName) }))
            setPassTypes(formattedPassTypes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typesData, typesError, typesLoading]);

    const addNewPass = () => {
        setCreateWasJustClicked(true);
        if (!selectedPassType || !expirationDate) {
            setOpenAlert(true);
            return;
        }
        runFetch({
            body: {
                userId: user.id,
                passId: selectedPassType,
                expirationDate: expirationDate
            }
        });
    };

    useEffect(() => {
        if (!loading && !error && data) {
            closeDialog();
            afterNewPassAdded();
        } else if (!loading && error) {
            setErrorAlertOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    const changeSelectedPassType = (value) => {
        setCreateWasJustClicked(false);
        setSelectedPassType(value)
    };

    const changeExpirationDate = (value) => {
        setCreateWasJustClicked(false);
        setExpirationDate(value)
    };

    const closeDialog = () => {
        setCreateWasJustClicked(false);
        setSelectedPassType("");
        setExpirationDate(getDefaultDate());
        setOpenAlert(false);
        onDialogClose();
    };

    if (!user) {
        return null;
    }

    return (
        <>
            <Dialog
                maxWidth="sm"
                onClose={() => closeDialog()}
                aria-labelledby="add-new-pass-dialog-title"
                open={open}
                transitionDuration={400}
            >
                <DialogTitle id="add-new-pass-dialog-title">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5" component="h5">
                                Új bérlet hozzáadása
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton aria-label="close" onClick={() => closeDialog()} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2} sx={{ padding: '8px' }}>
                        <Grid item>
                            <FormControl sx={{ width: "100%" }} >
                                <InputLabel id="pass-type-label">Bérlet típus</InputLabel>
                                <Select
                                    labelId="pass-type-label"
                                    value={selectedPassType}
                                    label="Bérlet típus"
                                    error={createWasJustClicked && !selectedPassType}
                                    onChange={(e) => changeSelectedPassType(e.target.value)}
                                >
                                    {passTypes.map(passType =>
                                        <MenuItem key={passType.id} value={passType.id}>{passType.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <MobileDatePicker
                                sx={{ width: "100%" }}
                                label="Lejárati dátum"
                                inputFormat="yyyy-MM-dd"
                                value={dayjs(expirationDate)}
                                error={createWasJustClicked && !expirationDate}
                                onChange={(newValue) => changeExpirationDate(newValue)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton sx={{ color: "#555" }} loading={loading} onClick={() => addNewPass()}>Mentés</LoadingButton>
                </DialogActions>
            </Dialog >
            <Snackbar open={openAlert && (!selectedPassType || !expirationDate)} onClose={() => setOpenAlert(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert severity="error" onClose={() => setOpenAlert(false)} sx={{ width: '100%' }}>
                    A mezők kitöltése kötelező!
                </Alert>
            </Snackbar>
            <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={() => setErrorAlertOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setErrorAlertOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {getReadableErrorMessage(error)}
                </Alert>
            </Snackbar>
        </>
    );
}

export default NewPassDialog;