export const getClassName = (className) => {
    switch (className) {
        case "pole": return "Rúdsport";
        case "aerial hoop": return "Karika";
        case "stretching": return "Nyújtás";
        case "children": return "Gyerek légi gimnasztika";
        case "functional training": return "Funkcionális tréning";
        case "maternity gym": return "Kismama torna";
        case "aerial gymnastics prep": return "Légi gimnasztika előkészítő (4-6 éves)";
        case "aerial gymnastics 6-10": return "Légi gimnasztika (6-10 éves)";
        case "aerial gymnastics 10-15": return "Légi gimnasztika (10-15 éves)";
        case "competition prep": return "Versenyfelkészítő";
        case "pole or aerial hoop": return "Rúdsport/Karika";
        case "private class": return "Magánóra";
        case "reserved private class": return "Foglalt magánóra";
        default: return "Ismeretlen edzésfajta"
    }
};

export const getPassName = (passName) => {
    switch (passName) {
        case "four_normal": return "4 alk. normál";
        case "four_student": return "4 alk. diák";
        case "eight_normal": return "8 alk. normál";
        case "eight_student": return "8 alk. diák";
        case "twelve_normal": return "12 alk. normál";
        default: return "Ismeretlen bérlettípus"
    }
};

export const getPassType = (passType) => {
    switch (passType) {
        case "normal": return "Normál";
        case "student": return "Diák";
        default: return "Ismeretlen"
    }
};