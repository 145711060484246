import { useEffect, useState } from 'react';
import { useStore } from '../hooksStore/store';
import { BASE_URL } from '../constants';
import { Box, Button, Grid, Snackbar, Alert, Typography, Card, CardContent, CardActions, IconButton, Tooltip, TextField, CircularProgress } from '@mui/material';
import useFetch from '../hooks/useFetch';
import { getDatePart } from '../utils/dateUtils';
import { getPassType } from '../utils/translationUtils';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditCalendarOutlinedIcon from '@mui/icons-material/EditCalendarOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import NewUserDialog from './NewUserDialog';
import ChangeCredentialsDialog from './ChangeCredentialsDialog';
import NewPassDialog from './NewPassDialog';
import ChangePass from './ChangePassDialog';
import LogsDialog from './LogsDialog';

function AdminUsersCards() {
    const [state, dispatch] = useStore();
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/user/all`, method: "GET" });
    const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
    const [changeCredentialsDialogOpen, setChangeCredentialsDialogOpen] = useState(false);
    const [newPassDialogOpen, setNewPassDialogOpen] = useState(false);
    const [changePassDialogOpen, setChangePassDialogOpen] = useState(false);
    const [logsDialogOpen, setLogsDialogOpen] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [successAlertMessage, setSuccessAlertMessage] = useState("");
    const [selectedUser, setSelectedUser] = useState();
    const [users, setUsers] = useState();

    useEffect(() => {
        if (state.user && !state.allUsers) {
            runFetch({});
        }
        if (state.allUsers) {
            setUsers(state.allUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, state.allUsers]);

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch("SET_ALL_USERS", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    const afterUserCreate = () => {
        setSuccessAlertMessage("A felhasználó sikeresen létre lett hozva!");
        setOpenSuccessAlert(true);
        runFetch({});
    };

    const afterChangeCredentials = () => {
        setSuccessAlertMessage("A bejelentkezési adatok sikeresen meg lettek változtatva!");
        setOpenSuccessAlert(true);
        runFetch({});
    };

    const openChangeCredentialsDialog = (user) => {
        setSelectedUser(user);
        setChangeCredentialsDialogOpen(true);
    };

    const afterNewPassAdded = () => {
        setSuccessAlertMessage("Új bérlet sikeresen hozzáadva!");
        setOpenSuccessAlert(true);
        runFetch({});
    };

    const openNewPassDialog = (user) => {
        setSelectedUser(user);
        setNewPassDialogOpen(true);
    };

    const afterPassHasChanged = () => {
        setSuccessAlertMessage("A bérlet sikeresen módosítva!");
        setOpenSuccessAlert(true);
        runFetch({});
    };

    const openChangePassDialog = (user) => {
        setSelectedUser(user);
        setChangePassDialogOpen(true);
    };

    const openLogsDialog = (user) => {
        setSelectedUser(user);
        setLogsDialogOpen(true);
    };

    const handleSearch = (searchValue) => {
        const filteredUsers = state.allUsers.filter(user => user.userName.includes(searchValue));
        setUsers(filteredUsers);
    }

    const getUsedNumCount = (user) => {
        const reserved = user.reservation.filter(res => res.calendar).length;
        return user.userPass[0].usedNum - reserved;
    }

    const getReservedAndUsedNumCount = (user) => {
        const reserved = user.reservation.filter(res => res.calendar).length;
        const used = user.userPass[0].usedNum - reserved;
        return reserved > 0 ? `(${used}+${reserved})` : used;
    }

    const isPassExpired = (user) => {
        return getDatePart(user.userPass[0].expirationDate) < getDatePart(new Date())
    }

    if (!state.user || !users) {
        return <Grid container alignItems="center" justifyContent="center"><CircularProgress /></Grid>;
    }

    return (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: '16px' }}>
                <Grid item xs={6}>
                    <TextField fullWidth placeholder='Keresés' onChange={(e) => handleSearch(e.target.value)} />
                </Grid>
                <Grid item xs={6}>
                    <Grid container justifyContent="end" alignItems="center" spacing={1}>
                        <Grid item>
                            <Button variant="outlined" size="small" sx={{ color: '#555', borderColor: '#555' }} startIcon={<PersonAddAltIcon />} onClick={() => setNewUserDialogOpen(true)}>Új felhasználó</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" size="small" sx={{ color: '#555', borderColor: '#555' }} startIcon={<ManageSearchIcon />} onClick={() => openLogsDialog(null)}>Tevékenységek</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
                    {users.map((user) => (
                        <Grid item container key={user.id} alignItems="center" justifyContent="center" xs={12} sm={6} md={4} lg={3} sx={{ height: "210px" }}>
                            <Card sx={{ minWidth: 275, backgroundColor: "#EFEEEB70" }}>
                                <CardContent>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography variant="h5" component="div">
                                                {user.userName}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body" sx={{ color: user.userPass.length && user.userPass[0].pass.occasionCount > getUsedNumCount(user) ? "inherit" : "#d32f2f", fontWeight: "bold" }}>
                                                {user.userPass.length ? `Bérlet: ${getReservedAndUsedNumCount(user)}/${user.userPass[0].pass.occasionCount}, ${getPassType(user.userPass[0].pass.type)}` : "Nincs bérlete"}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body" sx={{ fontWeight: 500, color: user.userPass.length && isPassExpired(user) ? "#d32f2f" : "inherit" }}>
                                                {user.userPass.length ? `Lejárati dátum: ${getDatePart(user.userPass[0].expirationDate)}` : ''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Grid container spacing={3} justifyContent="end">
                                        <Tooltip title="Felhasználóhoz tartozó tevékenységek">
                                            <IconButton aria-label="logs-button" onClick={() => openLogsDialog(user)} size="large">
                                                <PersonSearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Új bérlet hozzáadása">
                                            <IconButton aria-label="add-pass-button" onClick={() => openNewPassDialog(user)} size="large">
                                                <PostAddOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Bérlet megváltoztatása">
                                            <IconButton aria-label="edit-pass-button" disabled={user.userPass.length === 0} onClick={() => openChangePassDialog(user)} size="large">
                                                <EditCalendarOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Bejelentkezési adatok megváltoztatása">
                                            <IconButton aria-label="credentials-button" onClick={() => openChangeCredentialsDialog(user)} size="large">
                                                <VpnKeyOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <NewUserDialog open={newUserDialogOpen} afterUserCreate={() => afterUserCreate()} onDialogClose={() => setNewUserDialogOpen(false)} />
            <ChangeCredentialsDialog open={changeCredentialsDialogOpen} user={selectedUser} afterChangeCredentials={() => afterChangeCredentials()} onDialogClose={() => setChangeCredentialsDialogOpen(false)} />
            <NewPassDialog open={newPassDialogOpen} user={selectedUser} afterNewPassAdded={() => afterNewPassAdded()} onDialogClose={() => setNewPassDialogOpen(false)} />
            <ChangePass open={changePassDialogOpen} user={selectedUser} afterPassHasChanged={() => afterPassHasChanged()} onDialogClose={() => setChangePassDialogOpen(false)} />
            <LogsDialog open={logsDialogOpen} user={selectedUser} onDialogClose={() => setLogsDialogOpen(false)} />
            <Snackbar open={openSuccessAlert} onClose={() => setOpenSuccessAlert(false)} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert severity="success" onClose={() => setOpenSuccessAlert(false)} sx={{ width: '100%' }}>
                    {successAlertMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default AdminUsersCards;