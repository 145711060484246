import './TabContainer.scss';
import { useEffect, useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import { useStore } from '../hooksStore/store';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import Enrollment from '../enrollment/Enrollment';
import EnrollmentHeader from './EnrollmentHeader';
import AdminUsersCards from '../admin/AdminUsersCards';
import CalendarModification from '../calendarManagement/CalendarModification';

function TabContainer() {
    const [state, dispatch] = useStore();
    const [value, setValue] = useState(0);
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/user`, method: "GET" });

    useEffect(() => {
        if (!state.user) {
            runFetch({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state]);

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch("SET_USER", data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    function a11yProps(tabName) {
        return {
            id: `tab-${tabName}`,
            'aria-controls': `tab-${tabName}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (!state.user) {
        return null;
    }

    return (
        <>
            <EnrollmentHeader />
            {state.user.admin ? (
                <>
                    <>
                        <Tabs id="tab-container" value={value} onChange={handleChange} variant="fullWidth" textColor="inherit" aria-label="tab-container" TabIndicatorProps={{
                            style: {
                                backgroundColor: "#efefef"
                            }
                        }}>
                            <Tab label="Felhasználó beállítások" {...a11yProps("admin-users")} />
                            <Tab label="Órarend módosítás" {...a11yProps("admin-calendar")} />
                            <Tab label="Bejelentkezések" {...a11yProps("user")} />
                        </Tabs>
                    </>
                    <div
                        role="tabpanel"
                        hidden={value !== 0}
                        id={`tabpanel-admin-users`}
                        aria-labelledby={`tab-admin-users`}
                    >
                        {value === 0 && (
                            <AdminUsersCards />
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={value !== 1}
                        id={`tabpanel-admin-calendar`}
                        aria-labelledby={`tab-admin-calendar`}
                    >
                        {value === 1 && (
                            <CalendarModification />
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={value !== 2}
                        id={`tabpanel-user`}
                        aria-labelledby={`tab-user`}
                    >
                        {value === 2 && (
                            <Enrollment />
                        )}
                    </div>
                </>
            ) : <Enrollment />}
        </>
    );
}

export default TabContainer;