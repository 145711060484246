import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, TextField, Alert, CircularProgress } from '@mui/material';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { useEffect, useState, useCallback } from 'react';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { useStore } from '../hooksStore/store';

function Login() {
  const [, dispatch] = useStore();
  const navigate = useNavigate();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loginButtonDisabled, setlLoginButtonDisabled] = useState(false);
  const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/auth/login`, method: "POST" });
  const { runFetch: runFetchUser, data: dataUser, error: errorUser, loading: loadingUser } = useFetch({ url: `${BASE_URL}/user`, method: "GET" });

  const login = useCallback(() => {
    setlLoginButtonDisabled(true);
    runFetch({
      body: {
        userName: userName.trim(),
        password: password,
      }
    });
  }, [runFetch, userName, password]);

  useEffect(() => {
    if (!loading && error) {
      setlLoginButtonDisabled(false);
    }
    if (!loading && !error && data) {
      localStorage.setItem("user", JSON.stringify(data));
      runFetchUser({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  useEffect(() => {
    if (!loadingUser) {
      setlLoginButtonDisabled(false);
    }
    if (!loadingUser && !errorUser && dataUser) {
      dispatch("SET_USER", dataUser);
      if (dataUser.needsPasswordChange === false) {
        navigate('/enrollment');
      } else {
        navigate('/password-change');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUser, errorUser, loadingUser, navigate]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        login();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [login]);

  return (
    <Grid container justifyContent='center' alignItems='center' className='login-container'>
      <Grid item className="login-box">
        <Grid item className="login-box-content">
          <Grid container direction="column" spacing={2}>
            <Grid item container justifyContent='center'>
              <Typography variant='h4' className="login-title">Bejelentkezés</Typography>
            </Grid>
            <Grid item>
              <Alert severity="error" sx={{ display: !loading && error ? "flex" : "none", marginBottom: "8px" }}>{getReadableErrorMessage(error)}</Alert>
            </Grid>
            {(loading || loadingUser) && <Grid item container justifyContent='center' alignItems='center'><CircularProgress /></Grid>}
            <Grid item>
              <TextField label="Felhasználónév" variant="outlined" fullWidth error={!loading && error !== ""} onChange={(event) => setUserName(event.target.value)} />
            </Grid>
            <Grid item>
              <TextField label="Jelszó" variant="outlined" type="password" fullWidth error={!loading && error !== ""} onChange={(event) => setPassword(event.target.value)} />
            </Grid>
            <Grid item container>
              <Button variant='outlined' fullWidth className="login-button" disabled={loginButtonDisabled} onClick={() => login()}>Bejelentkezés</Button>
            </Grid>
            <Grid item container>
              <Grid item className="info-box">
                <Typography>Ha nincs még felhasználóneved, kérlek írj a <b>fruzsina.polesport@gmail.com</b> email címre ezzel kapcsolatban!</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Login;