import { useState } from "react";
import { useNavigate } from 'react-router-dom';

const useFetch = ({ url, method = "GET" }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const getAccessToken = () => {
        const user = localStorage.getItem("user");
        if (user) {
            return JSON.parse(user).access_token;
        }
        return null;
    }

    const runFetch = ({ body = null, params = null }) => {
        const accessToken = getAccessToken();

        if (!url.includes("login") && !accessToken) {
            navigate('/login');
            return;
        }

        const query = params ? Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&') : "";

        setLoading(true);
        fetch(`${url}?${query}`, {
            method: method,
            body: body ? JSON.stringify(body) : body,
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        })
            .then((res) => {
                return res.text();
            })
            .then((data) => {
                if (data) {
                    const parsedData = JSON.parse(data);
                    if (parsedData.statusCode === 401) {
                        navigate('/login');
                        return;
                    }
                    setError(parsedData.error || "");
                    setData(parsedData);
                }
                setLoading(false);
            })
    };

    return { runFetch, data, loading, error };
};

export default useFetch;