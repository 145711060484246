import './dialog.scss';
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ContactDialog(props) {
  const { onDialogClose, open } = props;
  const [prices, setPrices] = useState(null);

  const getPrices = async () => {
    const prices = await fetch('data/prices.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return prices.json();
  }

  useEffect(() => {
    getPrices().then(data => {
      data.classes.group.sort((a, b) => (a.type > b.type) ? 1 : 0);
      data.classes.private.sort((a, b) => (a.type > b.type) ? 1 : 0);
      setPrices(data);
    });
  }, []);

  return (
    <Dialog
      maxWidth="md"
      onClose={onDialogClose}
      aria-labelledby="contact-dialog-title"
      open={open}
      transitionDuration={400}
    >
      <DialogTitle id="contact-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h4" component="h4" className="dialog-title">
              Árak / Információ
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="flex-end">
              <IconButton aria-label="close" onClick={onDialogClose} size="large">
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid id="pricesContentDesktop" container className="dialog-content" spacing={3}>
          <Grid item container>
            <Grid item>
              <Typography variant='h5' fontWeight="bold">Csoportos órák</Typography>
            </Grid>
            <Grid item container direction="row">
              <Grid item xs={4}>
                <Typography></Typography>
              </Grid>
              <Grid item container justifyContent="center" xs={4}>
                <Typography className="price-title"><b>Normál</b></Typography>
              </Grid>
              <Grid item container justifyContent="center" xs={4}>
                <Typography className="price-title"><b>Diák</b></Typography>
              </Grid>
            </Grid>
            {prices && prices.classes && prices.classes.group && prices.classes.group.map(sportClass => {
              return (
                <Grid item container direction="row" key={sportClass.title}>
                  <Grid item container alignItems="center" xs={4}>
                    <Typography className="price-title"><b>{sportClass.title}</b></Typography>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" xs={4} className="price-item-container">
                    <Paper elevation={3} className="price-item">
                      <Grid container direction="column">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON} Ft</Typography>
                        {sportClass.type > 1 && <Typography variant="body2" className="price-text price-per-occasion">{sportClass.prices.ONE_PERSON / sportClass.type} Ft/alkalom</Typography>}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" xs={4} className="price-item-container">
                    <Paper elevation={3} className="price-item">
                      <Grid container direction="column">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON_STUDENT} Ft</Typography>
                        {sportClass.type > 1 && <Typography variant="body2" className="price-text price-per-occasion">{sportClass.prices.ONE_PERSON_STUDENT / sportClass.type} Ft/alkalom</Typography>}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography variant='h5' fontWeight="bold">Magánórák</Typography>
            </Grid>
            <Grid item container direction="row">
              <Grid item xs={4}>
                <Typography></Typography>
              </Grid>
              <Grid item container justifyContent="center" xs={4}>
                <Typography className="price-title"><b>Normál</b></Typography>
              </Grid>
              <Grid item container justifyContent="center" xs={4}>
                <Typography className="price-title"><b>Diák</b></Typography>
              </Grid>
            </Grid>
            {prices && prices.classes && prices.classes.private && prices.classes.private.map(sportClass => {
              return (
                <Grid item container direction="row" key={sportClass.title}>
                  <Grid item container alignItems="center" xs={4}>
                    <Typography className="price-title"><b>{sportClass.title}</b></Typography>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" xs={4} className="price-item-container">
                    <Paper elevation={3} className="price-item">
                      <Grid container direction="column">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON} Ft</Typography>
                        {sportClass.type > 1 && <Typography variant="body2" className="price-text price-per-occasion">{sportClass.prices.ONE_PERSON / sportClass.type} Ft/alkalom</Typography>}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" xs={4} className="price-item-container">
                    <Paper elevation={3} className="price-item">
                      <Grid container direction="column">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON_STUDENT} Ft</Typography>
                        {sportClass.type > 1 && <Typography variant="body2" className="price-text price-per-occasion">{sportClass.prices.ONE_PERSON_STUDENT / sportClass.type} Ft/alkalom</Typography>}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid id="pricesContentMobile" container className="dialog-content" spacing={3}>
          <Grid item container>
            <Grid item>
              <Typography variant='h5' fontWeight="bold">Csoportos órák</Typography>
            </Grid>
            {prices && prices.classes && prices.classes.group.map(sportClass => {
              return (
                <Grid container key={sportClass.title}>
                  <Grid item container direction="row" alignItems="center" justifyContent="space-between" className="price-container">
                    <Grid item xs={7}>
                      <Typography className="price-title"><b>{sportClass.title} - normál</b></Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Paper elevation={3} className="price-item">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON} Ft</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item container direction="row" alignItems="center" justifyContent="space-between" className="price-container">
                    <Grid item xs={7}>
                      <Typography className="price-title"><b>{sportClass.title} - diák</b></Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Paper elevation={3} className="price-item">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON_STUDENT} Ft</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography variant='h5' fontWeight="bold">Magánórák</Typography>
            </Grid>
            {prices && prices.classes && prices.classes.private.map(sportClass => {
              return (
                <Grid container key={sportClass.title}>
                  <Grid item container direction="row" alignItems="center" justifyContent="space-between" className="price-container">
                    <Grid item xs={7}>
                      <Typography className="price-title"><b>{sportClass.title} - normál</b></Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Paper elevation={3} className="price-item">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON} Ft</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item container direction="row" alignItems="center" justifyContent="space-between" className="price-container">
                    <Grid item xs={7}>
                      <Typography className="price-title"><b>{sportClass.title} - diák</b></Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Paper elevation={3} className="price-item">
                        <Typography className="price-text">{sportClass.prices.ONE_PERSON_STUDENT} Ft</Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid>
          <ul className="information-list">
            <li>
              <Typography>Az órák 60 percesek.</Typography>
            </li>
            <li>
              <Typography>A bérletek lejárati ideje 30 nap.</Typography>
            </li>
            <li>
              <Typography>Betegség esetén orvosi igazolással a bérlet meghosszabbítható, így az elmaradt órák pótolhatóak. Egyéb esetekben (nyaralás, családi okok vagy váratlan üzleti út) egy héttel előre egyeztetve a bérlet meghosszabbítható maximum két héttel.</Typography>
            </li>
            <li>
              <Typography>Betegen senki NEM vehet részt az órákon!</Typography>
            </li>
            <li>
              <Typography>Órát lemondani órakezdés előtt 8 órával van lehetőség, 8 órán belüli óralemondásnál az alkalom elveszik. (Kivéve igazolt betegség esetén)</Typography>
            </li>
            <li>
              <Typography>A diák kedvezmény csak érvényes, nappali tagozatos diákigazolvánnyal vehető igénybe!</Typography>
            </li>
            <li>
              <Typography>Bérlettel rendelkező vendégeinknek a bejelentkezés a weboldalon keresztül kötelező.</Typography>
            </li>
            <li>
              <Typography>Új ügyfelek, illetve bérlettel nem rendelkező régi vendégeink e-mail-ben vagy telefonon keresztül tudnak bejelentkezni az órára.</Typography>
            </li>
            <li>
              <Typography>Speciális igények esetén (90 perces óra, magán edzések, versenyfelkészülés stb.) érdeklődj telefonon vagy e-mail-ben.</Typography>
            </li>
            <li>
              <Typography>Az árváltoztatás jogát fenntartjuk!</Typography>
            </li>
          </ul>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ContactDialog;