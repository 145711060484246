import './dialog.scss';
import React, { useState, useLayoutEffect } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PreviewDialogContent from './PreviewDialogContent';

function ServiceDialog(props) {
  const { onDialogClose, title, dialogSize, description, open } = props;
  const [descriptionView, isDescriptionView] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageXs, setImageXs] = useState(2);
  const [isMobile, setIsMobile] = useState(false);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth >= 770) {
        setImageXs(dialogSize ? 6 : 2);
      } else if (window.innerWidth >= 560) {
        setImageXs(3);
      } else {
        setImageXs(4);
      }

      setIsMobile(window.innerWidth > 505 ? false : true);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [dialogSize]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    isDescriptionView(false);
  }

  const onPrivewClose = () => {
    isDescriptionView(true);
    setSelectedImage(null);
  }

  return (
    <Dialog
      maxWidth={dialogSize || "md"}
      fullWidth={true}
      onClose={onDialogClose}
      aria-labelledby="service-dialog-title"
      open={open}
      transitionDuration={400}
      fullScreen={!descriptionView}
    >
      {descriptionView &&
        <DialogTitle id="service-dialog-title" className="services-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h4" component="h4" className="dialog-title">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <IconButton aria-label="close" onClick={onDialogClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
      }
      {descriptionView ? (
        <DialogContent id="service-dialog-content" className="services-dialog-content">
          <Grid>
            {description && description.map((desc) =>
              <Grid key={desc.sectionId} container direction="column" spacing={4}>
                <Grid item>
                  <Grid container direction={isMobile ? "column" : (desc.imageAlign === "right" ? "row-reverse" : "row")} justifyContent="space-between" alignItems={desc.imageAlignVertical || "stretch"} spacing={3}>
                    {desc.image &&
                      <Grid item container direction="column" alignItems="center" justifyContent="center" xs={isMobile ? 12 : imageXs}>
                        <Grid item>
                          <img
                            className="small-image"
                            src={desc.image}
                            alt={desc.image}
                            onClick={() => handleImageClick(desc.imageOriginal)}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="caption" className="small-label">{desc.imageLabel}</Typography>
                        </Grid>
                      </Grid>
                    }
                    <Grid item xs={desc.image && !isMobile ? (12 - imageXs) : 12} sx={{ padding: "16px 0" }}>
                      {desc.section && desc.section.map((line) => {
                        let textClass = "normal-text";
                        if (line.bold) {
                          textClass = "bold-text";
                        } else if (line.underline) {
                          textClass = "underline-text";
                        }

                        return line.list ?
                          <ul key={line.lineId} className="list">
                            {line.listItems.map((listItem) =>
                              <li key={listItem}>
                                <Typography className={textClass}>{listItem}</Typography>
                              </li>
                            )}
                          </ul> :
                          <Typography key={line.lineId} variant={line.subltite ? 'h6' : 'body1'} className={line.subltite ? "" : textClass}>{line.text}</Typography>
                      }
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      ) : (
        <PreviewDialogContent images={[selectedImage]} initialActiveStep={0} onPrivewClose={onPrivewClose} />
      )}
    </Dialog>
  );
}

export default ServiceDialog;