import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, Alert, Snackbar, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { getPassName } from '../utils/translationUtils';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';

function ChangePass(props) {
    const { user, onDialogClose, afterPassHasChanged, open } = props;
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/pass/change-pass`, method: "POST" });
    const [newExpirationDate, setNewExpirationDate] = useState();
    const [newUsedNum, setNewUsedNum] = useState(0);
    const [originalUsedNum, setOriginalUsedNum] = useState(0);
    const [newUsedNumError, setNewUsedNumError] = useState("");
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);

    const setOriginalData = () => {
        setNewUsedNumError("");
        if (user && user.userPass.length > 0) {
            setNewExpirationDate(user.userPass[0].expirationDate);

            const reserved = user.reservation.filter(res => res.calendar).length;
            const used = user.userPass[0].usedNum - reserved;
            setNewUsedNum(used);
            setOriginalUsedNum(used);
        }
    };

    useEffect(() => {
        setNewUsedNumError("");
        if (user && user.userPass.length > 0) {
            setNewExpirationDate(user.userPass[0].expirationDate);

            const reserved = user.reservation.filter(res => res.calendar).length;
            const used = user.userPass[0].usedNum - reserved;
            setNewUsedNum(used);
            setOriginalUsedNum(used);
        }
    }, [user]);

    const isNewUsedNumValid = () => {
        return newUsedNum >= 0 && newUsedNum <= user.userPass[0].pass.occasionCount - user.reservation.filter(res => res.calendar).length;
    };

    const savePass = () => {
        if (!isNewUsedNumValid()) {
            setNewUsedNumError(`0 vagy nagyobb, de maximum ${user.userPass[0].pass.occasionCount - user.reservation.filter(res => res.calendar).length} lehet.`);
            return;
        }
        if (newExpirationDate === user.userPass[0].expirationDate && newUsedNum === originalUsedNum) {
            onDialogClose();
            return;
        }
        runFetch({
            body: {
                passId: user.userPass[0].id,
                expirationDate: newExpirationDate === user.userPass[0].expirationDate ? null : newExpirationDate,
                usedNum: newUsedNum === originalUsedNum ? null : newUsedNum + user.reservation.filter(res => res.calendar).length
            }
        });
    };

    useEffect(() => {
        if (!loading && !error && data) {
            onDialogClose();
            afterPassHasChanged();
        } else if (!loading && error) {
            setErrorAlertOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error, loading]);

    const changeExpirationDate = (value) => {
        setNewExpirationDate(value);
    };

    const changeUsedNum = (value) => {
        setNewUsedNumError("");
        if (value === "") {
            setNewUsedNum(null);
            return;
        }
        if (value === "e") {
            setNewUsedNum(originalUsedNum);
            return;
        }
        setNewUsedNum(Math.floor(parseInt(value)));
    };

    const closeWithoutSave = () => {
        onDialogClose();
        setOriginalData();
    };

    if (!user || user.userPass.length === 0) {
        return null;
    }

    return (
        <>
            <Dialog
                maxWidth="sm"
                onClose={() => closeWithoutSave()}
                aria-labelledby="change-pass-dialog-title"
                open={open}
                transitionDuration={400}
            >
                <DialogTitle id="change-pass-dialog-title">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5" component="h5">
                                Bérlet megváltoztatása
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton aria-label="close" onClick={() => closeWithoutSave()} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2} sx={{ padding: '8px' }}>
                        <Grid item>
                            <TextField label="Bérlet típus" variant="outlined" disabled value={getPassName(user.userPass[0].pass.passName)} sx={{ width: '100%' }} />
                        </Grid>
                        <Grid item>
                            <MobileDatePicker
                                sx={{ width: "100%" }}
                                label="Lejárati dátum"
                                inputFormat="yyyy-MM-dd"
                                value={dayjs(newExpirationDate)}
                                onChange={(newValue) => changeExpirationDate(newValue)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                sx={{ width: "100%" }}
                                label="Lehasznált alkalmak száma"
                                type="number"
                                value={newUsedNum}
                                onChange={(e) => changeUsedNum(e.target.value)}
                                error={newUsedNumError !== ""}
                                helperText={newUsedNumError}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <LoadingButton sx={{ color: "#555" }} loading={loading} onClick={() => savePass()}>Mentés</LoadingButton>
                </DialogActions>
            </Dialog >
            <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={() => setErrorAlertOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setErrorAlertOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {getReadableErrorMessage(error)}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ChangePass;