import './EnrollmentHeader.scss';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../hooksStore/store';
import { Button, Grid, Link, Typography } from '@mui/material';

function EnrollmentHeader() {
    const navigate = useNavigate();
    const [state, dispatch] = useStore();

    const logout = () => {
        localStorage.removeItem("user");
        dispatch("RESET_STORE");
        navigate('/');
    }

    if (!state.user) {
        return null;
    }

    return (
        <Grid container direction="row" alignItems="center" justifyContent="space-between" wrap="nowrap" className="enrollment-header">
            <Grid item>
                <img src="images/logo.svg" alt="Air Fitness logo" className="logo" />
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center" justifyContent="flex-end" spacing={2}>
                    <Typography variant="h6"><b>{state.user.userName}</b></Typography>
                    <Grid item>
                        <Link to={'/'}>
                            <Button variant="outlined" className="logout-button" onClick={() => logout()}>Kijelentkezés</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EnrollmentHeader;