import { initStore } from "./store";

const configureDataStore = () => {
    const getInitialStore = () => ({
        user: null,
        allUsers: null,
        passLoaded: null,
        passUsedNum: null,
        passOccasionCount: null,
        passType: null,
        passExpirationDate: null,
        calendarLoaded: null,
        calendar: null,
        reservationLoaded: null,
        reservation: null,
        calendarStartDate: null,
        calendarEndDate: null,
        disabledBackButton: false,
        disabledForwardButton: false,
    });

    const actions = {
        SET_USER: (state, user) => ({ user: user }),
        SET_ALL_USERS: (state, allUsers) => ({ allUsers: allUsers }),
        SET_PASS: (state, userPass) => {
            if (!userPass) {
                return {
                    passLoaded: new Date()
                }
            }
            return {
                passLoaded: new Date(),
                passUsedNum: userPass.usedNum,
                passOccasionCount: userPass.pass.occasionCount,
                passType: userPass.pass.type,
                passExpirationDate: userPass.expirationDate
            }
        },
        SET_CALENDAR: (state, calendar) => ({
            calendarLoaded: new Date(),
            calendar: calendar
        }),
        SET_CALENDAR_MODIFICATION: (state, calendar) => ({
            calendarModificationLoaded: new Date(),
            calendarModification: calendar
        }),
        SET_CALENDAR_DATES: (state, calendarDates) => ({
            calendarStartDate: calendarDates.start,
            calendarEndDate: calendarDates.end,
            disabledBackButton: calendarDates.disabledBackButton,
            disabledForwardButton: calendarDates.disabledForwardButton
        }),
        SET_RESERVATION: (state, reservation) => ({
            reservationLoaded: new Date(),
            reservation: reservation
        }),
        RESET_CALENDAR: () => ({ calendarLoaded: null }),
        RESET_ALL_USERS: () => ({ allUsers: null }),
        RESET_STORE: () => (getInitialStore())
    };

    initStore(actions, getInitialStore());
};

export default configureDataStore;