import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import {
  Routes, Route, BrowserRouter,
} from "react-router-dom";
import Home from './home/Home';
import Login from './login/Login';
import PasswordChange from './passwordChange/ChangePassword';
import configureDataStore from './hooksStore/dataStore';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/hu';
import TabContainer from './tabContainer/TabContainer';

const theme = createTheme();
configureDataStore();

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider adapterLocale="hu" dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="login" element={<Login />} />
              <Route path="password-change" element={<PasswordChange />} />
              <Route path="enrollment" element={<TabContainer />} />
            </Routes>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;