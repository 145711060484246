import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { useEffect } from 'react';
import { useStore } from '../hooksStore/store';
import PassStatus from './PassStatus';
import Calendar from './Calendar';

function Enrollment() {
    const [state, dispatch] = useStore();
    const { runFetch: reservationRunFetch, data: reservationData, error: reservationError, loading: reservationLoading } = useFetch({ url: `${BASE_URL}/reservation`, method: "GET" });
    const { runFetch: passRunFetch, data: passData, error: passError, loading: passLoading } = useFetch({ url: `${BASE_URL}/pass`, method: "GET" });

    useEffect(() => {
        if (state.user) {
            reservationRunFetch({});
            passRunFetch({
                params: {
                    userId: state.user.id,
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!reservationLoading && !reservationError && reservationData) {
            dispatch("SET_RESERVATION", reservationData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservationData, reservationError, reservationLoading]);

    useEffect(() => {
        if (!passLoading && !passError) {
            dispatch("SET_PASS", passData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [passData, passError, passLoading]);

    const refreshData = () => {
        reservationRunFetch({});
        passRunFetch({
            params: {
                userId: state.user.id,
            }
        });
    };

    return (
        <>
            {!state.user.admin && <PassStatus />}
            <Calendar refreshData={refreshData} />
        </>
    );
}

export default Enrollment;