import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Alert, Snackbar, List, ListItem, ListItemText, Divider, ListItemAvatar, Avatar, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { getReadableDateTime } from '../utils/dateUtils';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function LogsDialog(props) {
    const { onDialogClose, open, user } = props;
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/log/by-user`, method: "GET" });
    const { runFetch: runFetchAllLogs, data: dataAllLogs, error: errorAllLogs, loading: loadingAllLogs } = useFetch({ url: `${BASE_URL}/log`, method: "GET" });
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [logs, setLogs] = useState(null);

    useEffect(() => {
        if (open) {
            if (user) {
                setLogs(null);
                runFetch({
                    params: {
                        userId: user.id,
                    }
                });
            } else if (user === null) {
                setLogs(null);
                runFetchAllLogs({
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, open]);

    useEffect(() => {
        if (!loading && !error && data) {
            setLogs(data);
        } else if (!loading && error) {
            setErrorAlertOpen(true);
        }
    }, [data, error, loading]);

    useEffect(() => {
        if (!loadingAllLogs && !errorAllLogs && dataAllLogs) {
            setLogs(dataAllLogs);
        } else if (!loadingAllLogs && errorAllLogs) {
            setErrorAlertOpen(true);
        }
    }, [dataAllLogs, errorAllLogs, loadingAllLogs]);

    const closeDialog = () => {
        setErrorAlertOpen(false);
        onDialogClose();
    };

    return (
        <>
            <Dialog
                fullWidth
                onClose={() => closeDialog()}
                aria-labelledby="logs-dialog-title"
                open={open}
                transitionDuration={400}
            >
                <DialogTitle id="logs-dialog-title">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5" component="h5">
                                {user ? `${user?.userName} tevékenységnaplója` : `Felhasználó nélküli tevékenységnapló`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton aria-label="close" onClick={() => closeDialog()} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {logs === null &&
                        <Grid container alignItems="center" justifyContent="center"><CircularProgress /></Grid>
                    }
                    {logs && logs.length > 0 && (
                        <List sx={{ width: '100%' }}>
                            {logs.map(log => (
                                <>
                                    <ListItem key={log.id} sx={{ background: log.logType.logType === "error" ? "#FDEDED" : (log.logType.logType === "warning" ? "#FFF4E5" : "transparent") }}>
                                        <ListItemAvatar>
                                            <Avatar sx={{ backgroundColor: "transparent" }}>
                                                {log.logType.logType === "error" && <ErrorOutlineIcon style={{ color: 'D32F2F' }} />}
                                                {log.logType.logType === "warning" && <WarningAmberIcon style={{ color: 'ED6C02' }} />}
                                                {log.logType.logType === "info" && <InfoOutlinedIcon style={{ color: '0288D1' }} />}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={log.logMessage} secondary={getReadableDateTime(log.dateTime)} />
                                    </ListItem>
                                    <Divider />
                                </>
                            ))}
                        </List>
                    )}
                    {
                        logs && logs.length === 0 && user && (
                            <Typography>A felhasználó nem volt az elmúlt hónapban aktív.</Typography>
                        )
                    }
                    {
                        logs && logs.length === 0 && !user && (
                            <Typography>Az elmúlt hónapban nem volt felhasználó nélküli tevékenység.</Typography>
                        )
                    }
                </DialogContent>
            </Dialog>
            <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={() => setErrorAlertOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setErrorAlertOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {getReadableErrorMessage(error)}
                </Alert>
            </Snackbar>
        </>
    );
}

export default LogsDialog;