import React, { useEffect, useState } from 'react';
import { useStore } from '../hooksStore/store';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { Typography, Card, CardContent, Grid, Chip, Dialog, DialogTitle, DialogActions, Button, Snackbar, Alert, CircularProgress } from '@mui/material';
import { getClassName } from '../utils/translationUtils';
import CloseIcon from '@mui/icons-material/Close';
import { getReadableErrorMessage } from '../utils/errorUtil';

function ClassBox({ calendarElement, checkInUser, checkOutUser, refreshData, loadingId }) {
    const [, dispatch] = useStore();
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/reservation/delete`, method: "POST" });
    const [openDeleteReservationDialog, setOpenDeleteReservationDialog] = useState(false);
    const [userToRemove, setUserToRemove] = useState(null);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);

    const getClassTypeInterval = () => {
        const startDate = new Date(calendarElement.startTime);
        const endDate = new Date(calendarElement.endTime);

        return `${String(startDate.getHours()).padStart(2, '0')}:${String(startDate.getMinutes()).padStart(2, '0')} - ${String(endDate.getHours()).padStart(2, '0')}:${String(endDate.getMinutes()).padStart(2, '0')}`;
    };

    const onBoxClick = () => {
        if (calendarElement.activeUserReservationId) {
            checkOutUser(calendarElement.activeUserReservationId);
        } else {
            checkInUser(calendarElement.id)
        }
    };

    const getBackgroundColor = () => {
        if (calendarElement.activeUserReservationId && new Date(calendarElement.startTime) < new Date()) {
            return "repeating-linear-gradient(45deg, #fcf4a3, #fcf4a3 10px, #EFEEEB70 10px, #EFEEEB70 20px)";
        }
        if (calendarElement.activeUserReservationId) {
            return "#FCF4A3";
        }
        if (new Date(calendarElement.startTime) < new Date()) {
            return "repeating-linear-gradient(45deg, #EFEEEB70, #EFEEEB70 10px, #dbdad8 10px, #dbdad8 20px)";
        }
        return "#EFEEEB70";
    };

    const removeReservation = () => {
        const reservation = calendarElement.reservations.find(res => res.user.userName === userToRemove);
        if (reservation) {
            runFetch({
                body: {
                    reservationId: reservation.id
                }
            });
        }
    };

    useEffect(() => {
        if (!loading && error) {
            setErrorAlertOpen(true);
        }
        if (!loading && !error && data) {
            refreshData();
            dispatch("RESET_ALL_USERS");
            closeDialog();
            setSuccessAlertOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, error, data]);

    const handleErrorAlertClose = () => {
        setErrorAlertOpen(false);
    }

    const handleSuccessAlertClose = () => {
        setSuccessAlertOpen(false);
    }

    const closeDialog = () => {
        setUserToRemove(null);
        setOpenDeleteReservationDialog(false);
    };

    return (
        <>
            <Card elevation={3} sx={{ background: getBackgroundColor(), margin: "8px", width: "220px", cursor: "pointer" }}>
                <CardContent onClick={() => onBoxClick()}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Typography><b>{getClassTypeInterval()}</b></Typography>
                        {loadingId !== null && (loadingId === calendarElement.id || loadingId === calendarElement.activeUserReservationId) && <CircularProgress size={24} />}
                        {(loadingId === null || (loadingId !== calendarElement.id && loadingId !== calendarElement.activeUserReservationId)) && new Date(calendarElement.startTime) >= new Date() && calendarElement.activeUserReservationId && <CloseIcon color="error" />}
                    </Grid>
                    <Typography>{getClassName(calendarElement.classType.className)}</Typography>
                    <Typography>{`${calendarElement.reservations.length}/${calendarElement.maxPersonCount} fő`}</Typography>
                    {calendarElement.users && (
                        <Grid container spacing={1} sx={{ paddingTop: "16px" }}>
                            {calendarElement.users.map(user => (
                                <Grid item key={user}>
                                    <Chip label={user} onDelete={() => { setUserToRemove(user); setOpenDeleteReservationDialog(true); }} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </CardContent>
            </Card>
            <Dialog open={openDeleteReservationDialog} onClose={() => closeDialog()}>
                <DialogTitle>{"Biztosan törölni szeretnéd ezt a bejelentkezést?"}</DialogTitle>
                <DialogActions>
                    <Button sx={{ color: "#555" }} onClick={() => closeDialog()}>Mégse</Button>
                    <Button sx={{ color: "#d32f2f" }} onClick={() => removeReservation()}>Törlés</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={handleErrorAlertClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleErrorAlertClose} severity="error" sx={{ width: '100%' }}>
                    {error ? getReadableErrorMessage(error) : getReadableErrorMessage(error)}
                </Alert>
            </Snackbar>
            <Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={handleSuccessAlertClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleSuccessAlertClose} severity="success" sx={{ width: '100%' }}>
                    A bejelentkezés sikeresen törölve lett!
                </Alert>
            </Snackbar>
        </>
    );
}

export default ClassBox;