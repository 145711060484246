import React, { useState, useEffect } from 'react';
import { useStore } from '../hooksStore/store';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, DialogActions, Button, TextField, MenuItem, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getClassName } from '../utils/translationUtils';
import { TimeField } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { getDateForRequest, getDayNameByIndex } from '../utils/dateUtils';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { LoadingButton } from '@mui/lab';

function ClassModificationDialog(props) {
    const [, dispatch] = useStore();
    const { onDialogClose, selectedClass, startDate, afterClassHasBeenModified, afterClassHasBeenRemoved, open } = props;
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/calendar`, method: "POST" });
    const { runFetch: removeClassRunFetch, data: removeClassData, error: removeClassError, loading: removeClassLoading } = useFetch({ url: `${BASE_URL}/calendar/remove`, method: "POST" });
    const { runFetch: classTypesRunFetch, data: classTypesData, error: classTypesError, loading: classTypesLoading } = useFetch({ url: `${BASE_URL}/calendar/class-types`, method: "GET" });
    const { runFetch: reservationsRunFetch, data: reservationsData, error: reservationsError, loading: reservationsLoading } = useFetch({ url: `${BASE_URL}/reservation/by-class`, method: "GET" });
    const [classTypes, setClassTypes] = useState([]);
    const [selectedClassType, setSelectedClassType] = useState("");
    const [personCount, setPersonCount] = useState("");
    const [possibleDays, setPossibleDays] = useState([]);
    const [day, setDay] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageAlertOpen, setErrorMessageAlertOpen] = useState(false);
    const [justValidated, setJustValidated] = useState(false);
    const [checkedInUsers, setCheckedInUsers] = useState([]);
    const [backendError, setBackendError] = useState(null);

    useEffect(() => {
        classTypesRunFetch({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculatePossibleDays = () => {
        const localPossibleDays = [];
        for (let i = 0; i < 5; i++) {
            const currDate = new Date(startDate);
            const newDate = new Date(currDate).setDate(currDate.getDate() + i);
            localPossibleDays.push({
                id: getDateForRequest(newDate),
                name: getDayNameByIndex(i)
            });
        }
        setPossibleDays(localPossibleDays);
    };

    useEffect(() => {
        calculatePossibleDays();
        if (selectedClass && selectedClass.classType) {
            setSelectedClassType(selectedClass.classType.id);
        }
        if (selectedClass && selectedClass.maxPersonCount) {
            setPersonCount(selectedClass.maxPersonCount);
        }
        if (selectedClass && selectedClass.startTime) {
            const date = new Date(selectedClass.startTime);
            setDay(getDateForRequest(date));
            setStartTime(date.getTime());
        }
        if (selectedClass && selectedClass.endTime) {
            setEndTime(new Date(selectedClass.endTime).getTime());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClass]);

    useEffect(() => {
        if (!classTypesLoading && !classTypesError && classTypesData) {
            const formattedClassTypes = classTypesData.map(type => ({ id: type.id, name: getClassName(type.className) }));
            formattedClassTypes.sort((a, b) => (a.name > b.name) ? 1 : -1);
            setClassTypes(formattedClassTypes);
        } else if (!classTypesLoading && classTypesError) {
            setBackendError(classTypesError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classTypesData, classTypesError, classTypesLoading]);

    const closeDialog = () => {
        setSelectedClassType(null);
        setSelectedClassType("");
        setPersonCount("");
        setPossibleDays([]);
        setDay("");
        setStartTime("");
        setEndTime("");
        setJustValidated(false);
        setCheckedInUsers([]);
        setBackendError(null);
        onDialogClose();
    };

    const onChangePersonCount = (value) => {
        if (value === "") {
            setPersonCount(null);
            return;
        }
        if (value === "e") {
            setPersonCount(1);
            return;
        }
        if (value <= 0) {
            setPersonCount(1);
            return;
        }
        setPersonCount(Math.floor(parseInt(value)));
    };

    const saveClass = () => {
        let wasModified = false;
        const classToSave = {
            calendarId: selectedClass ? selectedClass.id : undefined
        };
        if (!selectedClass || selectedClass.classType.id !== selectedClassType) {
            classToSave.classTypeId = selectedClassType;
            wasModified = true;
        }
        if (!selectedClass || selectedClass.maxPersonCount !== personCount) {
            classToSave.maxPersonCount = personCount;
            wasModified = true;
        }
        const newStartTime = new Date(day);
        const localStartTime = new Date(startTime);
        newStartTime.setHours(localStartTime.getHours(), localStartTime.getMinutes());
        if (!selectedClass || selectedClass.startTime !== newStartTime.toISOString()) {
            classToSave.startTime = newStartTime.toISOString();
            wasModified = true;
        }
        const newEndTime = new Date(day);
        const localEndTime = new Date(endTime);
        newEndTime.setHours(localEndTime.getHours(), localEndTime.getMinutes());
        if (!selectedClass || selectedClass.endTime !== newEndTime.toISOString()) {
            classToSave.endTime = newEndTime.toISOString();
            wasModified = true;
        }

        if (wasModified) {
            runFetch({
                body: classToSave
            });
        } else {
            closeDialog();
        }
    };

    const validateClass = () => {
        setErrorMessageAlertOpen(false);
        setJustValidated(true);
        if (!selectedClassType || !personCount || !day || !startTime || !endTime) {
            setErrorMessage("Minden mező kitöltése kötelező!");
            setErrorMessageAlertOpen(true);
            return false;
        }
        const classDate = new Date(day);
        const classTime = new Date(startTime);
        classDate.setHours(classTime.getHours(), classTime.getMinutes());
        const currentDate = new Date();
        if (classDate < currentDate) {
            setErrorMessage("Az időpontot nem lehet múltbeli dátum!");
            setErrorMessageAlertOpen(true);
            return false;
        }
        if (startTime >= endTime) {
            setErrorMessage("Az óra kezdetének előbb kell lennie, mint az óra végének!");
            setErrorMessageAlertOpen(true);
            return false;
        }
        return true;
    };

    const onSaveClick = () => {
        const isValid = validateClass();
        if (isValid) {
            saveClass();
        }
    };

    useEffect(() => {
        if (!loading && !error && data) {
            dispatch("RESET_CALENDAR");
            closeDialog();
            afterClassHasBeenModified(selectedClass === "" ? true : false);
        } else if (!loading && error) {
            setBackendError(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, loading, error]);

    useEffect(() => {
        setOpenDeleteDialog(false);
        if (!removeClassLoading && !removeClassError && removeClassData) {
            dispatch("RESET_CALENDAR");
            dispatch("RESET_ALL_USERS");
            closeDialog();
            afterClassHasBeenRemoved();
        } else if (!removeClassLoading && removeClassError) {
            setBackendError(removeClassError)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeClassData, removeClassLoading, removeClassError]);

    const clickOnRemoveButton = () => {
        reservationsRunFetch({
            params: {
                calendarId: selectedClass.id
            }
        });
    }

    const removeClass = () => {
        removeClassRunFetch({
            body: {
                calendarId: selectedClass.id
            }
        });
    }

    useEffect(() => {
        setOpenDeleteDialog(false);
        if (!reservationsLoading && !reservationsError && reservationsData) {
            setCheckedInUsers(reservationsData.length === 0 ? [] :
                reservationsData.map(res => res.user.userName));
            setOpenDeleteDialog(true);
        } else if (!reservationsLoading && reservationsError) {
            setBackendError(reservationsError);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reservationsData, reservationsLoading, reservationsError]);

    if (selectedClass === null) {
        return null;
    }

    return (
        <>
            <Dialog
                onClose={() => closeDialog()}
                aria-labelledby="class-modification-dialog-title"
                open={open}
                transitionDuration={400}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "700px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="class-modification-dialog-title">
                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs={10}>
                            <Typography variant="h5" component="h5">
                                {selectedClass === "" ? "Új óra létrehozása" : "Óra módosítása"}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container justifyContent="flex-end">
                                <IconButton aria-label="close" onClick={() => closeDialog()} size="large">
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container direction="column" spacing={2} sx={{ padding: '8px' }}>
                        <Grid item>
                            <TextField
                                label="Óra típusa"
                                value={selectedClassType}
                                onChange={(e) => setSelectedClassType(e.target.value)}
                                fullWidth
                                select
                                error={justValidated && !selectedClassType}
                                InputLabelProps={{ shrink: true }}
                            >
                                {classTypes.map(classType =>
                                    <MenuItem key={classType.id} value={classType.id}>{classType.name}</MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Férőhely"
                                type="number"
                                value={personCount}
                                onChange={(e) => onChangePersonCount(e.target.value)}
                                fullWidth
                                error={justValidated && !personCount}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Nap"
                                value={day}
                                onChange={(e) => setDay(e.target.value)}
                                fullWidth
                                select
                                error={justValidated && !day}
                                InputLabelProps={{ shrink: true }}
                            >
                                {possibleDays.map(possibleDay =>
                                    <MenuItem key={possibleDay.id} value={possibleDay.id}>{possibleDay.name}</MenuItem>
                                )}
                            </TextField>
                        </Grid>
                        <Grid item>
                            <TimeField
                                sx={{ width: "100%" }}
                                ampm={false}
                                label="Óra kezdete"
                                value={dayjs(startTime)}
                                error={justValidated && !startTime}
                                onChange={(newValue) => { setStartTime(newValue) }}
                            />
                        </Grid>
                        <Grid item>
                            <TimeField
                                sx={{ width: "100%" }}
                                ampm={false}
                                label="Óra vége"
                                value={dayjs(endTime)}
                                error={justValidated && !endTime}
                                onChange={(newValue) => { setEndTime(newValue) }} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent={selectedClass !== "" ? "space-between" : "end"}>
                        {selectedClass !== "" && <Button sx={{ color: "#d32f2f", borderColor: "#d32f2f" }} variant="outlined" onClick={() => clickOnRemoveButton()}>Törlés</Button>}
                        <LoadingButton sx={{ color: "#555" }} loading={loading} onClick={() => onSaveClick()}>{selectedClass === "" ? "Létrehozás" : "Mentés"}</LoadingButton>
                    </Grid>
                </DialogActions>
            </Dialog >
            <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                <DialogTitle>{"Biztosan törölni szeretnéd ezt az órát?"}</DialogTitle>
                <DialogContent>
                    {checkedInUsers.length === 0 ? (
                        <Typography>Senki se jelentkezett be erre az órára.</Typography>
                    ) : (
                        <>
                            <Typography>A felsorolt személyek automatikusan törlődni fognak az óráról:</Typography>
                            <ul>
                                {checkedInUsers.map(user => (

                                    <li><Typography>{user}</Typography></li>

                                ))}
                            </ul>
                        </>

                    )}
                </DialogContent>
                <DialogActions>
                    <Button sx={{ color: "#555" }} onClick={() => setOpenDeleteDialog(false)}>Mégse</Button>
                    <LoadingButton sx={{ color: "#d32f2f" }} loading={removeClassLoading} onClick={() => removeClass()}>Törlés</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar open={backendError} autoHideDuration={6000} onClose={() => setBackendError(null)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setBackendError(null)} severity="error" sx={{ width: '100%' }}>
                    {getReadableErrorMessage(backendError)}
                </Alert>
            </Snackbar>
            <Snackbar open={errorMessageAlertOpen} autoHideDuration={6000} onClose={() => setErrorMessageAlertOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setErrorMessageAlertOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ClassModificationDialog;