import { useEffect, useState } from "react";

let actions = {};
let globalState = {};
let listeners = [];

export const useStore = () => {
    const [, setState] = useState(globalState);

    const dispatch = (actionIdentifier, withPayload) => {
        const newState = actions[actionIdentifier](globalState, withPayload);
        globalState = { ...globalState, ...newState };

        for (const listener of listeners) {
            listener(globalState);
        }
    };

    useEffect(() => {
        listeners.push(setState);
        return () => {
            listeners = listeners.filter(keepIf => keepIf !== setState);
        }
    }, [setState]);

    return [globalState, dispatch];
};

export const initStore = (userActions, initialState) => {
    if (initialState) {
        globalState = { ...globalState, ...initialState };
    }
    actions = { ...actions, ...userActions };
}