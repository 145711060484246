import './dialog.scss';
import React, { useState, useEffect } from 'react';
import { Grid, Typography, Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PreviewDialogContent from './PreviewDialogContent';

function GalleryDialog(props) {
  const NUMBER_OF_IMAGES = 15;
  const imageFolder = "/gallery/";
  const { onDialogClose, open } = props;
  const [listView, isListView] = useState(true);
  const [images, setImages] = useState([]);
  const [originalImages, setOriginalImages] = useState([]);
  const [activeStep, setActiveStep] = useState(0);

  const getImages = (isOriginal) => {
    const imageSrcs = [];
    for (let i = 0; i < NUMBER_OF_IMAGES; i++) {
      imageSrcs.push(imageFolder + (i + 1) + (isOriginal ? '_original' : '') + '.jpg');
    }
    return imageSrcs;
  }

  useEffect(() => {
    setImages(getImages(false));
  }, []);

  const handleImageClick = (index) => {
    if (originalImages.length === 0) {
      setOriginalImages(getImages(true));
    }
    setActiveStep(index);
    isListView(false);
  }

  const onPrivewClose = () => {
    isListView(true);
    setActiveStep(0);
  }

  return (
    <Dialog
      maxWidth="lg"
      onClose={onDialogClose}
      aria-labelledby="contact-dialog-title"
      open={open}
      transitionDuration={400}
      fullScreen={!listView}
    >
      {listView && (
        <DialogTitle id="contact-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h4" component="h4" className="dialog-title">
                Galéria
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <IconButton aria-label="close" onClick={onDialogClose} size="large">
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
      )}
      {listView ? (
        <DialogContent>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container justifyContent="center" columns={{ xs: 8, sm: 12, md: 12, lg: 12, xl: 12 }}>
              {images.map((src, index) => (
                <Grid item container alignItems="center" justifyContent="center" xs={8} sm={6} md={4} lg={2.4} xl={2.4} key={src} sx={{ height: "205px", marginBottom: "16px" }}>
                  <img
                    src={src}
                    alt={src}
                    className="image"
                    onClick={() => handleImageClick(index)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
      ) : (
        <PreviewDialogContent images={originalImages} initialActiveStep={activeStep} onPrivewClose={onPrivewClose} />
      )}
    </Dialog>
  );
}

export default GalleryDialog;