import './ChangePassword.scss';
import { useStore } from '../hooksStore/store';
import { Button, Grid, Typography, TextField, Alert } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import { BASE_URL } from '../constants';
import useFetch from '../hooks/useFetch';
import { getReadableErrorMessage } from '../utils/errorUtil';
import { useNavigate } from 'react-router-dom';

function PasswordChange() {
    const navigate = useNavigate();
    const [state, dispatch] = useStore();
    const [user] = useState(state.user);
    const { runFetch, data, error, loading } = useFetch({ url: `${BASE_URL}/user/change-password`, method: "POST" });
    const { runFetch: runFetchUser, data: dataUser, error: errorUser, loading: loadingUser } = useFetch({ url: `${BASE_URL}/user`, method: "GET" });
    const [newPassword, setNewPassword] = useState();
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();
    const [errorBeforeSending, setErrorBeforeSending] = useState("");

    useEffect(() => {
        if (!user) {
            runFetchUser({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!loadingUser && !errorUser && dataUser) {
            dispatch("SET_USER", dataUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUser, errorUser, loadingUser]);

    const changePassword = useCallback(() => {
        runFetch({
            body: {
                password: newPassword,
            }
        });
    }, [runFetch, newPassword]);

    useEffect(() => {
        if (!loading && !error && data) {
            navigate("/enrollment")
        }
    }, [data, error, loading]);

    const validateNewPassword = useCallback(() => {
        let isValid = true
        if (!newPassword || !newPasswordConfirmation) {
            isValid = false;
            setErrorBeforeSending("A mező kitöltése kötelező!");
        } else if (newPassword !== newPasswordConfirmation) {
            isValid = false;
            setErrorBeforeSending("Nem egyeznek a megadott jelszavak!");
        }
        return isValid;
    }, [setErrorBeforeSending, newPassword, newPasswordConfirmation]);

    const handleChangePasswordButtonClick = useCallback(() => {
        setErrorBeforeSending("");
        const isValid = validateNewPassword();
        if (isValid) {
            changePassword();
        }
    }, [setErrorBeforeSending, validateNewPassword, changePassword]);

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleChangePasswordButtonClick();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [handleChangePasswordButtonClick]);

    if (!user && !dataUser) {
        return null;
    }

    return (
        <Grid container justifyContent='center' alignItems='center' className='change-password-container'>
            <Grid item className="change-password-box">
                <Grid item className="change-password-box-content">
                    <Grid container direction="column" spacing={2}>
                        <Grid item container justifyContent='center'>
                            <Typography variant='h4' className="change-password-title">Jelszó megváltoztatása</Typography>
                        </Grid>
                        <Grid item>
                            <Alert severity="error" sx={{ display: errorBeforeSending !== "" ? "flex" : "none", marginBottom: "8px" }}>{errorBeforeSending}</Alert>
                            <Alert severity="error" sx={{ display: !loading && error ? "flex" : "none", marginBottom: "8px" }}>{getReadableErrorMessage(error)}</Alert>
                        </Grid>
                        <Grid item>
                            <TextField label="Felhasználónév" variant="outlined" fullWidth value={user ? user.userName : dataUser.userName} disabled />
                        </Grid>
                        <Grid item>
                            <TextField label="Új jelszó" variant="outlined" type="password" fullWidth error={(!loading && error !== "") || errorBeforeSending !== ""} onChange={(event) => setNewPassword(event.target.value)} />
                        </Grid>
                        <Grid item>
                            <TextField label="Új jelszó megerősítése" variant="outlined" type="password" fullWidth error={(!loading && error !== "") || errorBeforeSending !== ""} onChange={(event) => setNewPasswordConfirmation(event.target.value)} />
                        </Grid>
                        <Grid item container>
                            <Button variant='outlined' fullWidth className="change-password-button" onClick={() => handleChangePasswordButtonClick()}>Jelszó megváltoztatása</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PasswordChange;