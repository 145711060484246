import './PassStatus.scss';
import { useStore } from '../hooksStore/store';
import { Grid, Typography, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDatePart } from '../utils/dateUtils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ErrorIcon from '@mui/icons-material/Error';

function PassStatus() {
    const [state] = useStore();
    const [numberOfActiveReservation, setNumberOfActiveReservation] = useState(0);

    useEffect(() => {
        if (state.user && state.reservationLoaded) {
            const activeReservations = state.reservation.filter(reservation => reservation.user.id === state.user.id && new Date(reservation.calendar.startTime) > new Date());
            setNumberOfActiveReservation(activeReservations.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user, state.reservationLoaded]);

    const renderPassCircle = (index) => {
        if (index < state.passUsedNum - numberOfActiveReservation) {
            return <Grid item key={index}>
                <Tooltip title="Elhasznált alkalom">
                    <CheckCircleIcon sx={{ fontSize: 50, color: "#00A36C" }} />
                </Tooltip>
            </Grid>;
        } else if (index < state.passUsedNum) {
            return <Grid item key={index}>
                <Tooltip title="Foglalt alkalom">
                    <ErrorIcon sx={{ fontSize: 50, color: "#F4BB44" }} />
                </Tooltip>
            </Grid>;
        }
        return <Grid item key={index}>
            <Tooltip title="Szabad alkalom">
                <CircleOutlinedIcon sx={{ fontSize: 50 }} />
            </Tooltip>
        </Grid>;
    };

    if (!state.user || !state.passLoaded || !state.reservationLoaded) {
        return null;
    }

    return (
        <Grid container direction="column" className="pass-status-container">
            <Typography variant='h4'>{`Bérletem${state.passOccasionCount ? ` (${state.passUsedNum}/${state.passOccasionCount}) - ${state.passType === "normal" ? "Felnőtt" : "Diák"} - ${getDatePart(state.passExpirationDate)}` : ""}`}</Typography>
            {state.passType ? (
                <Grid item container direction="row" alignItems="center" sx={{ margin: "32px 0" }}>
                    <Grid item>
                        <Grid container direction="row" spacing={4}>
                            {
                                [...Array(state.passOccasionCount)].map((element, i) => (
                                    renderPassCircle(i)
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <Grid item sx={{ margin: "8px 0" }}>
                    <Typography>Jelenleg nincs érvényes bérleted!</Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default PassStatus;