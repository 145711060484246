const serviceCategories = new Map([
    ["airGymnasticsKids", {
        title: "Légi gimnasztika gyermekeknek / Air Gymnastics Kids (6-tól 13 éves korig)",
        description: [{
            sectionId: 1,
            image: "/images/air_gymnastics_kids_dialog_1.jpg",
            imageOriginal: "/images/air_gymnastics_kids_dialog_1_original.jpg",
            imageAlign: "right",
            section: [{
                lineId: 1,
                text: "A világ egyik legnőiesebb sportágára készíti fel a kislányokat az Air Fitness légi gimnasztika foglalkozása. Ezeken az órákon olyan szisztematikus, edzésjellegű sportoláson vesz részt a gyermek, ahol saját szintjének megfelelően, biztonságosan és látványosan fejlődik az állóképessége, mozgáskoordinációja és hajlékonysága. Különböző akrobatikai eszközökön (karika, rúd, silk) sajátíthatja el az alapokat. Az új és változatos akrobatikus elemek (folyamatos ismétlés és kontroll mellett) állandó fejlődést biztosítanak gyermeke számára. Az edzés megunhatatlan, hiszen minden óra más, játékos és sikerélményekkel van tele. A foglalkozásokon maximum 4 fő vehet részt előre egyeztetett időpontban, így kellő időt és figyelmet kap minden résztvevő gyerek. Célom, hogy szeresse azt amit csinál, hatalmas szórakozásnak élje meg, ne kötelező sportfoglalkozásnak."
            },
            {
                lineId: 2,
                text: "Az óra megtartása nincs minimum létszámlimithez kötve, és nincsenek létszámhoz kötött egyéb költségek!"
            },
            {
                lineId: 3,
                text: "Várom sok szeretettel a Te gyermekedet is!"
            }]
        },
        {
            sectionId: 2,
            section: [{
                lineId: 1,
                underline: true,
                text: "Az óra akrobatikus eszközei:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "80 cm belső átmérőjű, bandázsolt karika (egypontos felfüggesztéssel vagy kétpontos felfüggesztéssel)",
                    "2 db 42 cm átmérőjű Lupitpole rúd",
                    "Silk (selyem)",
                    "Lehuppanó szivacs"
                ]
            }]
        },
        {
            sectionId: 3,
            image: "/images/air_gymnastics_kids_dialog_2.jpg",
            imageOriginal: "/images/air_gymnastics_kids_dialog_2_original.jpg",
            imageAlign: "right",
            section: [{
                lineId: 1,
                underline: true,
                text: "Az óra menete:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Játékos bemelegítés",
                    "Tánc alapok elsajátítása/gyakorlása",
                    "Sportágspecifikus nyújtás",
                    "Akrobatikus gyakorlatok a rúdon, karikán/silken",
                    "Levezető, statikus nyújtás"
                ]
            }]
        },
        {
            sectionId: 4,
            section: [{
                lineId: 1,
                underline: true,
                text: "Milyen sportöltözékre lesz szükség?"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Testhezálló trikó/póló",
                    "Hosszú legings",
                    "Rövid nadrág",
                    "Zokni"
                ]
            }]
        },
        {
            sectionId: 5,
            section: [{
                lineId: 1,
                underline: true,
                text: "Bejelentkezés:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Telefonon: +36706056010",
                    "e-mail-ben: fruzsina.polesport@gmail.com"
                ]
            }]
        }]
    }],
    ["functionalTraining", {
        title: "Funkcionális tréning",
        description: [{
            sectionId: 1,
            section: [{
                lineId: 1,
                text: "A funkcionális edzések alatt a teljes test erősödik. A cél, hogy visszanyerd azt az erőt és mozgékonyságot, amelyet a lustaság, a korod vagy a nem teljes körű edzettség miatt elvesztettél. A tested újra olyan lesz, amilyennek a természet szánta: gyors, erős, dinamikus, karcsú és hajlékony. Az edzéseidet a céljaidnak megfelelően úgy tervezem meg, hogy a törzsizmokra és a test funkcionális megerősítésére összpontosítson. Általánosságban növeljük az izomerőt, a hajlékonyságot és az állóképességet, fokozzuk a törzs stabilitását, javítva a kardiovaszkuláris teljesítményt és a motoros készségeket. Ezzel elérjük, hogy a törzsizmok és a vállöv hatékonyan támogatják a gerincet, és szabadabb mozgást tesznek lehetővé a karok és lábak számára is. Emellett a törzs erősítése javítja a testtartást, az egyensúlyt és a mozgáskoordinációt, növeli a mozgékonyságot, és nagyobb erőkifejtést és sebességet tesz lehetővé. Ha szereted a céltudatos, kemény és változatos edzéseket, akkor a funkcionális tréninget Neked találták ki!"
            }]
        },
        {
            sectionId: 2,
            section: [{
                lineId: 1,
                text: "Kezdjük el azonnal, foglalj időpontot Magadnak!"
            }, {
                lineId: 2,
                text: "Az óra megtartása nincs minimum létszámlimithez kötve, és nincsenek létszámhoz kötött egyéb költségek!"
            }]

        },
        {
            sectionId: 3,
            section: [{
                lineId: 1,
                underline: true,
                text: "Az óra menete:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Bemelegítés",
                    "Erősítés szabad testúllyal különböző eszközök segítségével (bordásfal, erősítő szalagok, TRX, fitball labda, medizin stb.)",
                    "Levezető nyújtás"
                ]
            }]
        }, {
            sectionId: 4,
            section: [{
                lineId: 1,
                underline: true,
                text: "Milyen sportöltözékre lesz szükséged?"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Kényelmes póló/trikó/sportmelltartó",
                    "Melegítőnadrág/leggings",
                    "Sportcipő"
                ]
            }]
        }, {
            sectionId: 5,
            section: [{
                lineId: 1,
                underline: true,
                text: "Bejelentkezés:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Telefonon: +36706056010",
                    "e-mail-ben: fruzsina.polesport@gmail.com"
                ]
            }]
        }]
    }],
    ["poleSport", {
        title: "Rúdsport / Pole Sport",
        description: [{
            sectionId: 1,
            section: [{
                lineId: 1,
                text: "Manapság egyre nagyobb teret hódít a rúdtánc fitnesz változata: a rúdsport. Ezeken az edzéseken olyan akrobatikus elemeket sajátíthatsz el, amik egyszerre fejlesztik az erőt, az állóképességet és a hajlékonyságot. Nem utolsó sorban pedig nagyon látványos és a trükkök változatossága miatt megunhatatlan. A rúdsortban az a jó, hogy nem számít, ha nem sportoltál előtte semmit, teljesen az alapoktól kezdjük el, edzettségi szintedtől függetlenül. Nem lehetsz elég fiatal vagy idős ahhoz, hogy elkezd és nem számít a testalkatod sem! Saját ügyességi szintednek megfelelő szintrendszer szerint haladunk fokozatosan előre, a kezdő elemektől a haladó szinten keresztül a profi gyakorlatokig. Fejlődésedet (egyedülállóan csak az Air Fitness-ben!) külön, egyéni edzésnaplóban követjük végig. Az órákon egyedül vagy kiscsoportos órák keretein belül vehetsz részt, így kellő idő és figyelem jut rád minden órán. Vannak még félelmeid? Csak attól kell tartanod, hogy függőséget okoz."
            },
            {
                lineId: 2,
                text: "Állítsuk feje tetejére a világot, jelentkezz be még ma! Az óra megtartása nincs minimum létszámlimithez kötve, és nincsenek létszámhoz kötött egyéb költségek!"
            }]
        }, {
            sectionId: 2,
            section: [{
                lineId: 1,
                underline: true,
                text: "Az óra menete:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Alapos bemelegítés dinamikus nyújtó gyakorlatokkal",
                    "Sportágspecifikus erősítés",
                    "Akrobatikus gyakorlatok a rúdon",
                    "Levezető nyújtás"
                ]
            }]
        }, {
            sectionId: 3,
            section: [{
                lineId: 1,
                underline: true,
                text: "Milyen sportöltözékre lesz szükséged?"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Testhezálló topp és sportmelltartó",
                    "Testhezálló rövidnadrág",
                    "Zokni"
                ]
            }]
        }, {
            sectionId: 4,
            section: [{
                lineId: 1,
                underline: true,
                text: "Bejelentkezés:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Telefonon: +36706056010",
                    "e-mail-ben: fruzsina.polesport@gmail.com"
                ]
            }]
        }]
    }],
    ["aerialHoop", {
        title: "Karika / Aerial Hoop",
        description: [{
            sectionId: 1,
            image: "/images/aerial_hoop_dialog.jpeg",
            imageOriginal: "/images/aerial_hoop_dialog_original.jpg",
            imageAlign: "right",
            imageLabel: "Karika/Aerial Hoop",
            section: [{
                lineId: 1,
                text: "Ez egy igazán egyedi edzésforma, ami észrevétlenül hat a teljes tested fejlődésére. Egy különleges légakrobatikai eszközön, egy mennyezetről lelógatott vaskarikán sajátíthatod el a különböző akrobatikus, hajlékonysági- és erőelemeket. Megunhatatlan, hiszen minden órán mást tanítok Neked, mindig van egy újabb kedvenc trükk, amit minél hamarabb szeretnél a magadénak tudni. A fokozatosság itt is nagyon fontos, lépésről lépésre haladunk a kezdő elemektől a haladókig, míg a profi gyakorlatok is könnyedén elsajátíthatóak lesznek."
            }]
        }, {
            sectionId: 2,
            section: [{
                lineId: 1,
                text: "Mennyi idő alatt? Ez csak tőled függ! Az óra felépítése a Te képességeidhez mérten zajlik, egyéni edzésnapló és rád szabott szintrendszer szerint haladunk, ami látványos és gyors fejlődést biztosít. Sportmúltat nem igényel, nem számít a korod, a nemed vagy, hogy van rajtad egy kis felesleg...itt egyedül, vagy maximum 4 fős kiscsoportos edzések keretein belül vehetsz részt az órákon, így kellő figyelmet kapsz minden sportfoglalkozáson. Félsz a magasban? A plafonról lelógatott heveder (amire a karikát akasztjuk) tetszés szerint állítható, így fokozatosan szoksz hozzá a kellő magassághoz. A maximális biztonság érdekében még lehuppanó szivacs is kerül a karika alá. Van még kifogásod a fejlődésed ellen? Kételyek helyett inkább kitartásra lesz szükséged, mert garantálom, hogy teljesen levesz a lábadról! Az óra megtartása nincs minimum létszámlimithez kötve, és nincsenek létszámhoz kötött egyéb költségek!"
            }]
        }, {
            sectionId: 3,
            section: [{
                lineId: 1,
                underline: true,
                text: "Az órára válaszható karika típusok:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Egypontos felfüggesztés (mindenki számára elérhető)",
                    "Kétpontos felfüggesztés (mindenki számára elérhető)",
                    "Bandázsolt karika (mindenki számára elérhető)",
                    "Forgószemes karika (haladóknak, profiknak, versenyzőknek)",
                    "Szinterezett karika (profiknak, versenyzőknek)"
                ]
            }]
        }, {
            sectionId: 4,
            section: [{
                lineId: 1,
                underline: true,
                text: "Az óra menete:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Bemelegítés",
                    "Sportágspecifikus erősítés a karikán",
                    "Akrobatikus gyakorlatok a karikán",
                    "Levezető nyújtás"
                ]
            }]
        }, {
            sectionId: 5,
            section: [{
                lineId: 1,
                underline: true,
                text: "Milyen sportöltözékre lesz szükséged?"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Testhezálló trikó/póló",
                    "Hosszú leggings",
                    "Zokni, lábszárvédő"
                ]
            }]
        }, {
            sectionId: 6,
            section: [{
                lineId: 1,
                underline: true,
                text: "Bejelentkezés:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Telefonon: +36706056010",
                    "e-mail-ben: fruzsina.polesport@gmail.com"
                ]
            }]
        }]
    }],
    ["aboutMe", {
        title: "Magamról",
        description: [
            {
                sectionId: 1,
                section: [{
                    lineId: 1,
                    text: "Rajnai-Király Fruzsina vagyok, az Air Fitness Stúdió tulajdonosa, személyi edzője, rúdsport trénere és légakrobatika oktatója."
                }]
            },
            {
                sectionId: 2,
                section: [{
                    lineId: 1,
                    text: "Még csak 4 éves voltam, amikor először átléptem egy táncstúdió küszöbét, így már elég korán a mindennapjaim részévé vált a mozgás. 10 éven keresztül jártam klasszikus balettra, ami megalapozta a sportok iránti elhivatottságomat, kitartásomat. Már egészen kis koromban megtapasztalhattam, hogy mivel jár a színpadon szerepelni, éveken keresztül léptem fel országos versenyeken. Emellett belekóstoltam más táncstílusokba is, mint a hip hop és a társastánc, kortárstánc. Tanulmányaimra koncentrálva ezeket később abba kellett hagynom, ami akkor hatalmas űrt hagyott az életemben. Később kezdtem el újra sportolni, edzőtermekbe jártam, valamint otthoni edzésterveket találtam ki és már akkor elkezdtem kisebb edzéseket tartani a barátnőimnek. Később saját céljaimnak megfelelő edzőtermet rendeztem be otthonra."
                }]
            },
            {
                sectionId: 3,
                section: [{
                    lineId: 1,
                    text: "2015-ben vettem részt életem első rúdsport edzésén, ahol úgy éreztem, megtaláltam a kihívásokkal teli, nekem való mozgásformát, amit a mai napig szívvel lélekkel, örömmel csinálok. Kezdő edzéseim egyikén fogalmazódott meg bennem, hogy én is ezzel szeretnék foglalkozni. Egy év után már versenyeken vettem részt, több helyezést is elértem, 2017-ben pedig megtartottam az első csoportos órámat, így a hobbim nagyon hamar a hivatásommá vált. Közben csoportos fitness instruktor oktatói végzettségem lett, az iskolában volt alkalmam belekóstolni az aerobic világába is. 2019-ben a csoportos órák mellett elkezdtem személyre szabott, magánedzéseket tartani háznál, otthon is megtalálható eszközökkel, így segítettem annak is fitt maradni, akinek nem volt ideje edzőterembe járni, vagy különleges céljai voltak, amit rövidebb időn belül szeretett volna elérni. Az illem ekkor úgy kívánta, hogy újra iskolába járjak, és személyi edzői végzettségem is legyen."
                }]
            },
            {
                sectionId: 4,
                section: [{
                    lineId: 1,
                    text: "Az extrémsportok a mai napig hatalmas kihívásokkal teli élményt jelentenek nekem, így nyáron wakeboard-ozni, télen snowboard-ozni járok. Úgy érzem nekem folyamatosan mozognom kell, így lesz a hobbiból életforma. Hiszem, hogy az a jó edző, aki maga is sportoló, aki olyan edzéseket tart, amit ő maga is végig tud csinálni."
                }]
            },
            {
                sectionId: 5,
                section: [{
                    lineId: 1,
                    text: "Ezt a lendületet, spotszeretetet adom át Neked az óráimon. Segítek, hogy az edzéseket ne kényszerként éld meg, hanem élvezd is azt amit csinálsz, miközben a szakmai irányításom mellett eléred a céljaidat."
                }]
            },
            {
                sectionId: 6,
                section: [{
                    lineId: 1,
                    text: "2020-ban valóra vált egy újabb álmom: alkalmam nyílt saját edzőtermet vezetni, megszületett az Air Fitness. A rúdsort és az aerialhoop és az ezekhez kapcsolódó sportágspecifikus kiscsoprtos és magán edzések mellett helyet kapott a funkcionális tréning és a TRX is."
                }]
            },
            {
                sectionId: 7,
                section: [{
                    lineId: 1,
                    text: "Mivel a sportot nem lehet elég korán elkezdeni, 2021-ben indítottam el 4-6 évesek számára kiscsoportos edzéseimet, így az óvodásoknak is lehetőséget adva, hogy minél hamarabb beleszeressenek a légi gimnasztikába."
                }]
            },
            {
                sectionId: 8,
                section: [{
                    lineId: 1,
                    text: "Abban az esetben engem keresel, ha mersz nagyot álmodni, ha más akarsz lenni, mint tegnap! Ha van benned annyi kitartás és motiváció, hogy megvalósítsd a kitűzött céljaidat! Ha valóban edzeni akarsz és eredményt elérni! Tőlem megkapsz mindent, már csak a Te elhatározásod kell! Ne várj holnapig, foglalj időpontot még ma!"
                }]
            }
        ]
    }],
    ["stretching", {
        title: "Nyújtás / Stretching",
        description: [{
            sectionId: 1,
            section: [{
                lineId: 1,
                subtitle: true,
                bold: true,
                text: "Nyújtás a mindennapjaidért:"
            }]
        },
        {
            sectionId: 2,
            section: [{
                lineId: 1,
                text: "Bármilyen sport elengedhetetlen része a nyújtás, ami épp annyira fontos, mint maga az edzés. Fogadjunk, hogy nálad nem így van! Sokan szeretik kihagyni a nyújtást és ezért a testünk elég alattomosan áll bosszút. Az izmok túlzott feszessége a mozgástartományok beszűküléséhez vezethet. Ha szereted saját magad megvakarni a hátad, akkor a nyújtás kötelező, bónuszként kifejezetten jól is esik."
            }]
        },
        {
            sectionId: 3,
            image: "/images/simple_stretching.jpeg",
            imageOriginal: "/images/simple_stretching_original.jpg",
            imageAlign: "right",
            imageLabel: "Nyújtás a mindennapjaidért",
            section: [{
                lineId: 1,
                text: "Ha már megtörtént a baj, és az edzés után már csak hazamenni van erőd, akkor szakíts egy kicsit hosszabb időt a nyújtásra, külön óra keretein belül. A 60 perces óra alatt kifejezetten a tested hajlékonyságát fejlesztjük különböző könnyen elsajátítható dinamikus és statikus gyakorlatokkal. Segítek megtanulni a pontos, precíz kivitelezést, a helyes légzést, valamint, hogy tudatosan használd a tested, hogy tudd, mikor melyik izmod nyúlik igazán."
            }]
        }, {
            sectionId: 4,
            section: [{
                lineId: 1,
                text: "Akár már az első óra után érezheted a stretching jótékony hatásait:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Az izmok könnyebbnek tűnnek, szabadabban mozognak",
                    "Szellemileg is erősebbnek érezheted magad",
                    "Nyugodtabb, kiegyensúlyozottabb leszel"
                ]
            }]
        }, {
            sectionId: 5,
            section: [{
                lineId: 1,
                text: "Hosszabb távon pedig érezheted, láthatod és tapasztalhatod:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "A tested egyre hajlékonyabb lesz",
                    "Egyenesebb lesz a gerinced",
                    "Javul a testtartásod",
                    "Növekszik az állóképességed",
                    "Kevésbé leszel sérülékeny"
                ]
            }]
        }, {
            sectionId: 6,
            section: [{
                lineId: 1,
                text: "Az órára összeállított gyakorlatok tökéletesen illeszkednek a saját igényeidhez, céljaidhoz, testi adottságaidhoz és bármilyen funkcionális és súlyzós edzésprogramba is beilleszthető."
            }]
        }, {
            sectionId: 7,
            section: [{
                lineId: 1,
                subtitle: true,
                bold: true,
                text: "Nyújtás egy nagyobb célért:"
            }]
        }, {
            sectionId: 8,
            image: "/images/stretching_for_a_goal.jpg",
            imageOriginal: "/images/stretching_for_a_goal_original.jpg",
            imageAlign: "right",
            imageLabel: "Nyújtás egy nagyobb célért",
            section: [{
                lineId: 1,
                text: "A nyújtással egyéb célok is elérhetőek, ez mind motiváció és ego kérdése."
            }, {
                lineId: 2,
                text: "Gyorsabban szeretnél fejlődni?"
            }, {
                lineId: 3,
                text: "Magasabbra akarod emelni/lendíteni a lábad?"
            }, {
                lineId: 4,
                text: "Szeretnél végre lemenni spárgába vagy megcsinálni a hidak különböző variációit?"
            }, {
                lineId: 5,
                text: "Korántsem elérhetetlen, de egy valami biztos! Nem fog egyik napról a másikra menni. Fontos, hogy fokozatosan szoktassuk hozzá a tested a terhelésnek, különben a kezdeti motivációd hamar elveszhet, ha másnap nem tudsz kikelni az ágyból. Egy gyönyörű spárga akár hónapokat vagy éveket is igénybe vehet. Nagyon sok múlik a velünk született adottságoktól, viszont az ízületi mozgékonyság bármikor fejleszthető, akár holnaptól is. Már a kezdetektől (a tökéletes technika elsajátítása mellett) a távoli célokra összpontosítunk pl.: egy híd esetében nagyobb hangsúlyt fektetünk a vállak, a hát és a derék hajlékonyságának a fejlesztésére, a spárgáknál pedig a lábaké és a csípőé a főszerep. A hatékonyabb fejlődés érdekében jógatéglákat, térdvédőt, fitball labdát és nyújtószalagot is használunk."
            }, {
                lineId: 6,
                text: "Minél később kezded el, annál később készül róla tökéletes fotó!"
            }, {
                lineId: 7,
                text: "Jelenkezz be még ma! Az óra megtartása nincs minimum létszámlimithez kötve, és nincsenek létszámhoz kötött egyéb költségek!"
            }]
        }, {
            sectionId: 9,
            section: [{
                lineId: 1,
                underline: true,
                text: "Az óra menete:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Alapos bemelegítés",
                    "Intenzív erősítés dinamikus nyújtással kombinálva, hogy az izmok és ízületek alaposan átmelegedjenek",
                    "Különböző statikus (aktív, passzív) nyújtó gyakorlatok"
                ]
            }]
        }, {
            sectionId: 10,
            section: [{
                lineId: 1,
                underline: true,
                text: "Milyen sportöltözékre lesz szükséged?"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Kényelmes póló/sportmelltartó",
                    "Hosszú melegítőnadrág/leggings",
                    "Zokni/balett gyakorlócipő"
                ]
            }]
        }, {
            sectionId: 11,
            section: [{
                lineId: 1,
                underline: true,
                text: "Bejelentkezés:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Telefonon: +36706056010",
                    "e-mail-ben: fruzsina.polesport@gmail.com"
                ]
            }]
        }]
    }],
    ["contact", {
        title: "Kapcsolat",
        dialogSize: "sm",
        description: [{
            sectionId: 1,
            image: "/images/prices.jpeg",
            imageOriginal: "/images/prices_original.jpg",
            imageAlign: "right",
            imageAlignVertical: "center",
            section: [{
                lineId: 1,
                text: "AIR FITNESS VECSÉS"
            }, {
                lineId: 2,
                text: "2220 Vecsés, Jókai Mór utca 85."
            }, {
                lineId: 3,
                text: "+36706056010"
            }, {
                lineId: 4,
                text: "fruzsina.polesport@gmail.com"
            }]
        }, {
            sectionId: 2,
            section: [{
                lineId: 1,
                underline: true,
                text: "Parkolási lehetőség:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "Utcán, ingyenes parkolás",
                ]
            }]
        }, {
            sectionId: 3,
            section: [{
                lineId: 1,
                underline: true,
                text: "Tömegközlekedés Budapestről, Üllőről, Monorról:"
            }, {
                lineId: 2,
                list: true,
                listItems: [
                    "S50-es személyvonat, Vecsés-Kertekalja megállótól 8 perc séta",
                    "580-as Volánbusz, Iskola utca megállótól 2 perc séta"
                ]
            }]
        }]
    }]
]);

export const getServiceCategory = (key) => {
    return serviceCategories.get(key);
}
