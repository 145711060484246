import './Main.scss';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Grid, Typography, Box, Link, Button } from '@mui/material';
import ServiceDialog from '../dialog/ServiceDialog';
import PricesDialog from '../dialog/PricesDialog';
import GalleryDialog from '../dialog/GalleryDialog';
import { getServiceCategory } from '../utils/ServiceCategoryUtil';

function Main() {
  const navigate = useNavigate();
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [serviceDialogTitle, setServiceDialogTitle] = useState(null);
  const [serviceDialogSize, setServiceDialogSize] = useState(null);
  const [serviceDialogDescription, setServiceDialogDescription] = useState(null);
  const [pricesDialogOpen, setPricesDialogOpen] = useState(false);
  const [galleryDialogOpen, setGalleryDialogOpen] = useState(false);

  const handleServiceDialogOpen = (category) => {
    const selectedCategory = getServiceCategory(category);
    if (selectedCategory) {
      setServiceDialogTitle(selectedCategory.title);
      setServiceDialogSize(selectedCategory.dialogSize);
      setServiceDialogDescription(selectedCategory.description);
      setServiceDialogOpen(true);
    }
  };

  const handleServiceDialogClose = () => {
    setServiceDialogOpen(false);
  };

  const navigateToLoginPage = () => {
    navigate('/login');
  }

  const circleMenuItems = (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item container alignItems="center" justifyContent="center" xs={12} sm={6} md={4} sx={{ height: "210px" }}>
          <Grid className="circle-image-container">
            <img src="images/pole_sport.jpg" alt="Pole Sport" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("poleSport")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Rúdsport / Pole Sport</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/stretching.jpg" alt="Stretching" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("stretching")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Nyújtás / Stretching</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/aerial_hoop.jpeg" alt="Aerial Hoop" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("aerialHoop")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Karika / Aerial Hoop</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/air_gymnastics_kids.jpeg" alt="Air Gymnastics Kids" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("airGymnasticsKids")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Gyerek légi gimnasztika</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/functional_training.jpeg" alt="Functional Training" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("functionalTraining")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Funkcionális tréning</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/gallery.jpg" alt="Gallery" width='132px' height='132px' className="circle-image" onClick={() => setGalleryDialogOpen(true)} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Galéria</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/about_me.jpeg" alt="About me" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("aboutMe")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Magamról</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/prices.jpeg" alt="Prices" width='132px' height='132px' className="circle-image" onClick={() => setPricesDialogOpen(true)} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Árak / Információ</Typography>
        </Grid>
        <Grid item container xs={12} sm={6} md={4} sx={{ height: "210px" }} alignItems="center" justifyContent="center">
          <Grid className="circle-image-container">
            <img src="images/logo.jpg" alt="Contacts" width='132px' height='132px' className="circle-image" onClick={() => handleServiceDialogOpen("contact")} />
          </Grid>
          <Typography variant="h5" component="h5" className="circle-text">Kapcsolat</Typography>
        </Grid>
      </Grid>
    </Box>
  );

  return <>
    <Grid container direction="row" justifyContent="space-between" className="introduction-contianer">
      <Grid item className="left-side">
        <img src="images/main2.png" alt="Air Fitness main" height="100%" width="100%" className="main-image" />
      </Grid>
      <Grid item container className="right-side" alignItems="center" justifyContent="center">
        <Grid item container direction="column" spacing={4}>
          <Grid item>
            <Typography id="main-text" variant="h3" component="h3">
              Legyél jobb, mint tegnap voltál!
            </Typography>
          </Grid>
          <Grid item>
            <Typography id="secondary-text" variant="h4" component="h4">
              Be better!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="space-around" className="services-container">
      <Grid item className="full-width">
        <>{circleMenuItems}</>
      </Grid>
    </Grid>
    <Grid container justifyContent="center" sx={{ padding: "50px 0" }}>
      <Button sx={{
        color: "#555",
        borderColor: "#555",
        width: "500px",
        height: "60px",
        margin: "8px",
        '&:hover': {
          color: "#555",
          backgroundColor: "#f5f5f5",
          borderColor: "#555"
        }
      }} variant='outlined' size="large" onClick={() => navigateToLoginPage()}>Bejelentkezés órára</Button>
    </Grid>
    {window.innerWidth >= 815 ?
      <Grid container>
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe width="100%" height="500" id="gmap_canvas" title="map" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJa5hnRyLAQUcRJzvnKsDkFAY&&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
            </iframe>
            <a href="https://yt2.org"></a>
          </div>
        </div>
      </Grid> :
      <Grid container>
        <Link
          href="https://www.google.com/maps/place/Vecs%C3%A9s,+J%C3%B3kai+M%C3%B3r+u.+85,+2220/@47.4048532,19.2774495,17z/data=!3m1!4b1!4m6!3m5!1s0x4741c0224767986b:0x614e4c02ae73b27!8m2!3d47.4048496!4d19.2800244!16s%2Fg%2F11hjkb0r5_?entry=ttu"
          target="_blank"
          className="link"
          underline="hover">
          <img src="images/map2.jpg" alt="Map" className="map-image" />
        </Link>
      </Grid>
    }
    <ServiceDialog open={serviceDialogOpen} title={serviceDialogTitle} dialogSize={serviceDialogSize} description={serviceDialogDescription} onDialogClose={handleServiceDialogClose} />
    <PricesDialog open={pricesDialogOpen} onDialogClose={() => setPricesDialogOpen(false)} />
    <GalleryDialog open={galleryDialogOpen} onDialogClose={() => setGalleryDialogOpen(false)} />
  </>;
}

export default Main;
