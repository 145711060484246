import { Typography, Card, CardContent, Grid, Snackbar, Alert } from '@mui/material';
import { useState } from 'react';
import { getClassName } from '../utils/translationUtils';
import CloseIcon from '@mui/icons-material/Close';

function ClassBoxForModification({ calendarElement, onClassClick }) {
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const getClassTypeInterval = () => {
        const startDate = new Date(calendarElement.startTime);
        const endDate = new Date(calendarElement.endTime);

        return `${String(startDate.getHours()).padStart(2, '0')}:${String(startDate.getMinutes()).padStart(2, '0')} - ${String(endDate.getHours()).padStart(2, '0')}:${String(endDate.getMinutes()).padStart(2, '0')}`;
    };

    const onBoxClick = () => {
        setOpenErrorDialog(false);
        if (new Date(calendarElement.startTime) < new Date()) {
            setErrorMessage("Múltbeli órát nem tudsz módosítani!")
            setOpenErrorDialog(true);
            return;
        }
        onClassClick(calendarElement)
    };

    const getBackgroundColor = () => {
        if (new Date(calendarElement.startTime) < new Date()) {
            return "repeating-linear-gradient(45deg, #EFEEEB70, #EFEEEB70 10px, #dbdad8 10px, #dbdad8 20px)";
        }
        return "#EFEEEB70";
    };

    return (
        <>
            <Card elevation={3} sx={{ background: getBackgroundColor(), margin: "8px", width: "220px", cursor: "pointer" }}>
                <CardContent onClick={() => onBoxClick()}>
                    <Grid container direction="row" justifyContent="space-between">
                        <Typography><b>{getClassTypeInterval()}</b></Typography>
                        {calendarElement.activeUserReservationId && <CloseIcon color="error" />}
                    </Grid>
                    <Typography>{getClassName(calendarElement.classType.className)}</Typography>
                    <Typography>{`${calendarElement.maxPersonCount} fő`}</Typography>
                </CardContent>
            </Card>
            <Snackbar open={openErrorDialog} autoHideDuration={6000} onClose={() => setOpenErrorDialog(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={() => setOpenErrorDialog(false)} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
}

export default ClassBoxForModification;